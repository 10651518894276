import { Card, Col, DatePicker, Input, Row, Select, Spin, Modal, message, Typography, Label, Checkbox } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { Article, DividerCustom, ImageCustom, InputMd, InputTitle, Item, PCustom, Summary, SummaryItem, TextFooter, TextSm, Title } from './style';

export default function AIAContract({ contractTypes, contractType, project, record, onClose, onChangeType, visible }) {
    const logo = "/assets/images/logo-aia.png";
    const currentDate = moment().format('DD') + 'th day of ' + moment().format('MMMM') + ' in the year ' + moment().format('YYYY');
    const { t } = useTranslation();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [subVendor, setSubVendor] = useState(null);
    const [fields, setFields] = useState([]);
    const [subContractors, setSubContractors] = useState([]);
    const [subContractorOptions, setSubContractorOptions] = useState([]);
    const [subcontractorLocale, setSubcontractorLocale] = useState('');
    const [projectNumber, setProjectNumber] = useState('');
    const [dateDescription, setDateDescription] = useState(currentDate);

    const [projectName, setProjectName] = useState('');
    const [projectAddress, setProjectAddress] = useState('');
    const [cityStateZipCode, setCityStateZipCode] = useState('');
    const [subcontractValue, setSubcontractValue] = useState('0.00');
    const [date, setDate] = useState(moment());
    //
    const [contractTitle, setContractTitle] = useState('');
    const [contractDate, setContractDate] = useState({ day: '', month: '', year: '' });
    const [contractor, setContractor] = useState({ name: 'Consult 2 Build INC', address: '11501 Lake Underhill Rd11501 Lake Underhill Rd', cityAndState: 'Orlando FL 32825', otherInfo: '407-704-7834' });
    const [subcontractor, setSubcontractor] = useState({ name: '', address: '', cityAndState: '', otherInfo: '' });
    const [primeContractDate, setPrimeContractDate] = useState('');
    const [owner, setOwner] = useState({ name: '', address: '', cityAndState: '', otherInfo: '' });
    const [projectData, setProjectData] = useState({ name: '', address: '', cityAndState: '', otherInfo: '' });
    const [architect, setArchitect] = useState({ name: '', address: '', cityAndState: '', otherInfo: '' });
    const [box1_6_2, setBox1_6_2] = useState(false);
    const [box2_6_2, setBox2_6_2] = useState(false);
    const [box3_6_2, setBox3_6_2] = useState(false);
    const [workDescription, setWorkDescription] = useState('');
    const [box1_9_1, setBox1_9_1] = useState(false);
    const [box2_9_1, setBox2_9_1] = useState(false);
    const [box3_9_1, setBox3_9_1] = useState(false);
    const [box1_9_2_2, setBox1_9_2_2] = useState(false);
    const [box2_9_2_2, setBox2_9_2_2] = useState(false);
    const [field1_9_2_2, setField1_9_2_2] = useState('');
    const [field2_9_2_2, setField2_9_2_2] = useState('');
    const [field3_9_2_2, setField3_9_2_2] = useState('');
    const [portionOfWork, setPortionOfWork] = useState('');
    const [substantialCompletion, setSubstantialCompletion] = useState('');
    const [field1_10_1, setField1_10_1] = useState('');
    const [field2_10_1, setField2_10_1] = useState('');
    const [item10_2_1, setItem10_2_1] = useState('');
    const [price10_2_1, setPrice10_2_1] = useState('');
    const [item10_2_2, setItem10_2_2] = useState('');
    const [price10_2_2, setPrice10_2_2] = useState('');
    const [conditionsForAcceptance10_2_2, setConditionsForAcceptance10_2_2] = useState('');
    const [item10_3, setItem10_3] = useState('');
    const [unitsAndLimitations10_3, setUnitsAndLimitations10_3] = useState('');
    const [pricePerUnit10_3, setPricePerUnit10_3] = useState('');
    const [item10_4, setItem10_4] = useState('');
    const [price10_4, setPrice10_4] = useState('');
    const [field11_1_2, setField11_1_2] = useState('');
    const [field11_1_3, setField11_1_3] = useState('');
    const [field11_1_8_1, setField11_1_8_1] = useState('');
    const [field11_1_8_1_1, setField11_1_8_1_1] = useState('');
    const [field11_1_8_2, setField11_1_8_2] = useState('');
    const [field11_4, setField11_4] = useState('');
    const [field1_12_1_2, setField1_12_1_2] = useState('');
    const [field2_12_1_2, setField2_12_1_2] = useState('');
    const [field3_12_1_2, setField3_12_1_2] = useState('');
    const [field4_12_1_2, setField4_12_1_2] = useState('');
    const [field5_12_1_2, setField5_12_1_2] = useState('');
    const [field6_12_1_2, setField6_12_1_2] = useState('');
    const [field1_12_1_3, setField1_12_1_3] = useState('');
    const [field2_12_1_3, setField2_12_1_3] = useState('');
    const [field1_12_1_4, setField1_12_1_4] = useState('');
    const [field2_12_1_4, setField2_12_1_4] = useState('');
    const [field1_12_1_6, setField1_12_1_6] = useState('');
    const [field2_12_1_6, setField2_12_1_6] = useState('');
    const [field3_12_1_6, setField3_12_1_6] = useState('');
    const [field4_12_1_6, setField4_12_1_6] = useState('');
    const [field5_12_1_6, setField5_12_1_6] = useState('');
    const [field6_12_1_6, setField6_12_1_6] = useState('');
    const [field1_12_1_8, setField1_12_1_8] = useState('');
    const [field2_12_1_8, setField2_12_1_8] = useState('');
    const [field3_12_1_8, setField3_12_1_8] = useState('');
    const [field4_12_1_8, setField4_12_1_8] = useState('');
    const [field1_12_2_1, setField1_12_2_1] = useState('N/A');
    const [field2_12_2_1, setField2_12_2_1] = useState('N/A');
    const [field1_13_1, setField1_13_1] = useState('');
    const [field1_13_2, setField1_13_2] = useState('');
    const [field1_13_3, setField1_13_3] = useState('');
    const [contractorsRepresentative1, setContractorsRepresentative1] = useState({ name: 'Marco Garavelo', company: 'Consult 2 Build INC', address: '11501 Lake Underhill Rd11501 Lake Underhill Rd', cityAndState: 'Orlando FL 32825', otherInfo: '407-704-7834', mail: 'marco@jmcquality.com' });
    const [contractorsRepresentative2, setContractorsRepresentative2] = useState({ name: 'Ghaby Jahchan', company: 'Consult 2 Build INC', address: '11501 Lake Underhill Rd11501 Lake Underhill Rd', cityAndState: 'Orlando FL 32825', otherInfo: '407-704-7834' });
    const [subcontractorsRepresentative, setSubcontractorsRepresentative] = useState({ name: '', address: '', cityAndState: '', otherInfo: '', mail: '' });
    const [field14_4_3, setField14_4_3] = useState('');
    const [field1_15_1, setField1_15_1] = useState('N/A');
    const [field2_15_1, setField2_15_1] = useState('N/A');
    const [field3_15_1, setField3_15_1] = useState('N/A');

    const fetchRecords = () => {
        setError('');

        if (record !== null) {
            let contractFieldDTO = record.contractFieldDTO;
            let contractDateSplit = contractFieldDTO.contractDate.split('/');
            let contractorSplit = contractFieldDTO.contractor.split('$');
            let subcontractorSplit = contractFieldDTO.subcontractor.split('$');
            let ownerSplit = contractFieldDTO.owner.split('$');
            let projectDataSplit = contractFieldDTO.projectData.split('$');
            let architectSplit = contractFieldDTO.architect.split('$');
            let contractorsRepresentative1Split = contractFieldDTO.contractorsRepresentative1.split('$');
            let contractorsRepresentative2Split = contractFieldDTO.contractorsRepresentative2.split('$');
            let subcontractorsRepresentativeSplit = contractFieldDTO.subcontractorsRepresentative.split('$');

            setSubContractorOptions([{ key: record.subVendor.id, value: record.subVendor.companyName, label: record.subVendor.companyName }]);
            setSubVendor(record.subVendor);
            setProjectNumber(record.projectNumber);
            setDateDescription(record.dateDescription);
            setSubcontractorLocale(record.subcontractorLocale);
            setProjectName(record.projectName);
            setProjectAddress(record.projectAddress);
            setCityStateZipCode(record.cityStateZipCode);
            setDate(moment(record.date));
            setFields(record.fields);
            setContractTitle(contractFieldDTO.contractTitle);
            setContractDate({ day: contractDateSplit[0], month: contractDateSplit[1], year: contractDateSplit[2] });
            setContractor({ name: contractorSplit[0].substring(6), address: contractorSplit[1].substring(9), cityAndState: contractorSplit[2].substring(14), otherInfo: contractorSplit[3].substring(11) });
            setSubcontractor({ name: subcontractorSplit[0].substring(6), address: subcontractorSplit[1].substring(9), cityAndState: subcontractorSplit[2].substring(14), otherInfo: subcontractorSplit[3].substring(11) });
            setPrimeContractDate(contractFieldDTO.primeContractDate);
            setOwner({ name: ownerSplit[0].substring(6), address: ownerSplit[1].substring(9), cityAndState: ownerSplit[2].substring(14), otherInfo: ownerSplit[3].substring(11) });
            setProjectData({ name: projectDataSplit[0].substring(6), address: projectDataSplit[1].substring(9), cityAndState: projectDataSplit[2].substring(14), otherInfo: projectDataSplit[3].substring(11) });
            setArchitect({ name: architectSplit[0].substring(6), address: architectSplit[1].substring(9), cityAndState: architectSplit[2].substring(14), otherInfo: architectSplit[3].substring(11) });
            setBox1_6_2(contractFieldDTO.box1_6_2);
            setBox2_6_2(contractFieldDTO.box2_6_2);
            setBox3_6_2(contractFieldDTO.box3_6_2);
            setWorkDescription(contractFieldDTO.workDescription);
            setBox1_9_1(contractFieldDTO.box1_9_1);
            setBox2_9_1(contractFieldDTO.box2_9_1);
            setBox3_9_1(contractFieldDTO.box3_9_1);
            setBox1_9_2_2(contractFieldDTO.box1_9_2_2);
            setBox2_9_2_2(contractFieldDTO.box2_9_2_2);
            setField1_9_2_2(contractFieldDTO.field1_9_2_2);
            setField2_9_2_2(contractFieldDTO.field2_9_2_2);
            setField3_9_2_2(contractFieldDTO.field3_9_2_2);
            setPortionOfWork(contractFieldDTO.portionOfWork);
            setSubstantialCompletion(contractFieldDTO.substantialCompletion);
            setField1_10_1(contractFieldDTO.field1_10_1);
            setField2_10_1(contractFieldDTO.field2_10_1);
            setItem10_2_1(contractFieldDTO.item10_2_1);
            setPrice10_2_1(contractFieldDTO.price10_2_1);
            setItem10_2_2(contractFieldDTO.item10_2_2);
            setPrice10_2_2(contractFieldDTO.price10_2_2);
            setConditionsForAcceptance10_2_2(contractFieldDTO.conditionsForAcceptance10_2_2);
            setItem10_3(contractFieldDTO.item10_3);
            setUnitsAndLimitations10_3(contractFieldDTO.unitsAndLimitations10_3);
            setPricePerUnit10_3(contractFieldDTO.pricePerUnit10_3);
            setItem10_4(contractFieldDTO.item10_4);
            setPrice10_4(contractFieldDTO.price10_4);
            setField11_1_2(contractFieldDTO.field11_1_2);
            setField11_1_3(contractFieldDTO.field11_1_3);
            setField11_1_8_1(contractFieldDTO.field11_1_8_1);
            setField11_1_8_1_1(contractFieldDTO.field11_1_8_1_1);
            setField11_1_8_2(contractFieldDTO.field11_1_8_2);
            setField11_4(contractFieldDTO.field11_4);
            setField1_12_1_2(contractFieldDTO.field1_12_1_2);
            setField2_12_1_2(contractFieldDTO.field2_12_1_2);
            setField3_12_1_2(contractFieldDTO.field3_12_1_2);
            setField4_12_1_2(contractFieldDTO.field4_12_1_2);
            setField5_12_1_2(contractFieldDTO.field5_12_1_2);
            setField6_12_1_2(contractFieldDTO.field6_12_1_2);
            setField1_12_1_3(contractFieldDTO.field1_12_1_3);
            setField2_12_1_3(contractFieldDTO.field2_12_1_3);
            setField1_12_1_4(contractFieldDTO.field1_12_1_4);
            setField2_12_1_4(contractFieldDTO.field2_12_1_4);
            setField1_12_1_6(contractFieldDTO.field1_12_1_6);
            setField2_12_1_6(contractFieldDTO.field2_12_1_6);
            setField3_12_1_6(contractFieldDTO.field3_12_1_6);
            setField4_12_1_6(contractFieldDTO.field4_12_1_6);
            setField5_12_1_6(contractFieldDTO.field5_12_1_6);
            setField6_12_1_6(contractFieldDTO.field6_12_1_6);
            setField1_12_1_8(contractFieldDTO.field1_12_1_8);
            setField2_12_1_8(contractFieldDTO.field2_12_1_8);
            setField3_12_1_8(contractFieldDTO.field3_12_1_8);
            setField4_12_1_8(contractFieldDTO.field4_12_1_8);
            setField1_12_2_1(contractFieldDTO.field1_12_2_1);
            setField2_12_2_1(contractFieldDTO.field2_12_2_1);
            setField1_13_1(contractFieldDTO.field1_13_1);
            setField1_13_2(contractFieldDTO.field1_13_2);
            setField1_13_3(contractFieldDTO.field1_13_3);
            setContractorsRepresentative1({ name: contractorsRepresentative1Split[0].substring(6), company: contractorsRepresentative1Split[1].substring(9), address: contractorsRepresentative1Split[2].substring(9), cityAndState: contractorsRepresentative1Split[3].substring(14), otherInfo: contractorsRepresentative1Split[4].substring(11), mail: contractorsRepresentative1Split[5].substring(6) });
            setContractorsRepresentative2({ name: contractorsRepresentative2Split[0].substring(6), company: contractorsRepresentative2Split[1].substring(9), address: contractorsRepresentative2Split[2].substring(9), cityAndState: contractorsRepresentative2Split[3].substring(14), otherInfo: contractorsRepresentative2Split[4].substring(11) });
            setSubcontractorsRepresentative({ name: subcontractorsRepresentativeSplit[0].substring(6), address: subcontractorsRepresentativeSplit[1].substring(9), cityAndState: subcontractorsRepresentativeSplit[2].substring(14), otherInfo: subcontractorsRepresentativeSplit[3].substring(11), mail: subcontractorsRepresentativeSplit[4].substring(6) });
            setField14_4_3(contractFieldDTO.field14_4_3);
            setField1_15_1(contractFieldDTO.field1_15_1);
            setField2_15_1(contractFieldDTO.field2_15_1);
            setField3_15_1(contractFieldDTO.field3_15_1);
        } else {
            setSubVendor(null);
            setProjectNumber('');
            setDateDescription(currentDate);
            setSubcontractorLocale('');
            setProjectName('');
            setProjectAddress('');
            setCityStateZipCode('');
            setDate(moment());
            setFields([]);
            setSubContractors([]);
            setSubContractorOptions([]);
            setContractTitle('');
            setContractDate({ day: '', month: '', year: '' });
            setContractor({ name: 'Consult 2 Build INC', address: '11501 Lake Underhill Rd11501 Lake Underhill Rd', cityAndState: 'Orlando FL 32825', otherInfo: '407-704-7834' });
            setSubcontractor({ name: '', address: '', cityAndState: '', otherInfo: '' });
            setPrimeContractDate('');
            setOwner({ name: '', address: '', cityAndState: '', otherInfo: '' });
            setProjectData({ name: '', address: '', cityAndState: '', otherInfo: '' });
            setArchitect({ name: '', address: '', cityAndState: '', otherInfo: '' });
            setBox1_6_2(false);
            setBox2_6_2(false);
            setBox3_6_2(false);
            setWorkDescription('');
            setBox1_9_1(false);
            setBox2_9_1(false);
            setBox3_9_1(false);
            setBox1_9_2_2(false);
            setBox2_9_2_2(false);
            setField1_9_2_2('');
            setField2_9_2_2('');
            setField3_9_2_2('');
            setPortionOfWork('');
            setSubstantialCompletion('');
            setField1_10_1('');
            setField2_10_1('');
            setItem10_2_1('');
            setPrice10_2_1('');
            setItem10_2_2('');
            setPrice10_2_2('');
            setConditionsForAcceptance10_2_2('Approved by Consult 2 Build');
            setItem10_3('');
            setUnitsAndLimitations10_3('');
            setPricePerUnit10_3('');
            setItem10_4('');
            setPrice10_4('');
            setField11_1_2('');
            setField11_1_3('');
            setField11_1_8_1('');
            setField11_1_8_1_1('');
            setField11_1_8_2('');
            setField11_4('');
            setField1_12_1_2('');
            setField2_12_1_2('');
            setField3_12_1_2('');
            setField4_12_1_2('');
            setField5_12_1_2('');
            setField6_12_1_2('');
            setField1_12_1_3('');
            setField2_12_1_3('');
            setField1_12_1_4('');
            setField2_12_1_4('');
            setField1_12_1_6('');
            setField2_12_1_6('');
            setField3_12_1_6('');
            setField4_12_1_6('');
            setField5_12_1_6('');
            setField6_12_1_6('');
            setField1_12_1_8('');
            setField2_12_1_8('');
            setField3_12_1_8('');
            setField4_12_1_8('');
            setField1_12_2_1('N/A');
            setField2_12_2_1('N/A');
            setField1_13_1('');
            setField1_13_2('');
            setField1_13_3('');
            setContractorsRepresentative1({ name: 'Marco Garavelo', company: 'Consult 2 Build INC', address: '11501 Lake Underhill Rd11501 Lake Underhill Rd', cityAndState: 'Orlando FL 32825', otherInfo: '407-704-7834', mail: 'marco@jmcquality.com' });
            setContractorsRepresentative2({ name: 'Ghaby Jahchan', company: 'Consult 2 Build INC', address: '11501 Lake Underhill Rd11501 Lake Underhill Rd', cityAndState: 'Orlando FL 32825', otherInfo: '407-704-7834' });
            setSubcontractorsRepresentative({ name: '', address: '', cityAndState: '', otherInfo: '', mail: '' });
            setField14_4_3('');
            setField1_15_1('N/A');
            setField2_15_1('N/A');
            setField3_15_1('N/A');

            if (project) {
                setProjectNumber(project.id);
                setProjectName(project.description);

                if (project.property && project.property.address) {
                    const address = project.property.address;
                    setProjectAddress(address.addressLineOne);
                    setCityStateZipCode(address.city + ", " + address.state + address.zipCode);
                    setProjectData({ name: project.description, address: address.addressLineOne, cityAndState: address.city + ", " + address.state + ' ' + address.zipCode, otherInfo: '' });
                }

                if (project.ownership && project.ownership.address) {
                    const address = project.ownership.address;
                    setOwner({ name: project.ownership.name, address: address.addressLineOne, cityAndState: address.city + ", " + address.state + ' ' + address.zipCode, otherInfo: project.ownership.representativePhone });
                }

                if (project.architect && project.architect.address) {
                    const address = project.architect.address;
                    setArchitect({ name: project.architect.companyName, address: address.addressLineOne, cityAndState: address.city + ", " + address.state + ' ' + address.zipCode, otherInfo: project.architect.representativePhone });
                }
            }
            fetchSubContractors();
        }
    };

    const fetchSubContractors = async () => {
        setLoading(true);
        try {
            const filterList = [
                { field: 'type', value: 'SUBCONTRACTOR', restriction: 'EQUAL' }
            ];
            const { data } = await api.post('/subVendors/findAllPost', filterList);
            let options = [];
            data.forEach((item) => {
                options.push({ key: item.id, value: item.companyName, label: item.companyName });
            });

            setSubContractors(data);
            setSubContractorOptions(options);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleChange = (value) => {
        onChangeType(value);
    };

    const handleChangeSubcontractor = (value) => {
        subContractors.some((sub) => {
            if (sub.companyName === value) {
                setSubVendor(sub);
                setSubcontractorLocale(sub.address);

                let subcontractorValues = { name: sub.companyName, address: '', cityAndState: '', otherInfo: '' };
                let subcontractorsRep = { name: sub.companyName, address: '', cityAndState: '', mail: '', otherInfo: '' };

                if (sub.contact) {
                    subcontractorValues.name = sub.companyName;
                    subcontractorValues.otherInfo = sub.contact.mainPhone;
                    subcontractorsRep.name = sub.contact.firstName + " " + sub.contact.lastName;
                    subcontractorsRep.mail = sub.contact.mainEmail;
                    subcontractorsRep.otherInfo = sub.contact.mainPhone;

                    const address = sub.contact.address;
                    if (address) {
                        subcontractorValues.address = address.addressLineOne;
                        subcontractorValues.cityAndState = address.city + ", " + address.state + ' ' + address.zipCode;
                        subcontractorsRep.address = address.addressLineOne;
                        subcontractorsRep.cityAndState = address.city + ", " + address.state + ' ' + address.zipCode;
                    }
                }
                setSubcontractor(subcontractorValues);
                setSubcontractorsRepresentative(subcontractorsRep);
                return true;
            }
        });
        setError('');
    };

    const contractSchema = Yup.object({ subVendor: Yup.string().required("Subcontractor is required!") });

    const handleSave = async () => {
        setLoading(true);

        let contractFieldDTO = {
            contractTitle: contractTitle,
            contractDate: contractDate.day + '/' + contractDate.month + '/' + contractDate.year,
            contractor: 'name: ' + contractor.name + ' $address: ' + contractor.address + ' $cityAndState: ' + contractor.cityAndState + ' $otherInfo: ' + contractor.otherInfo,
            subcontractor: 'name: ' + subcontractor.name + ' $address: ' + subcontractor.address + ' $cityAndState: ' + subcontractor.cityAndState + ' $otherInfo: ' + subcontractor.otherInfo,
            primeContractDate: primeContractDate,
            owner: 'name: ' + owner.name + ' $address: ' + owner.address + ' $cityAndState: ' + owner.cityAndState + ' $otherInfo: ' + owner.otherInfo,
            projectData: 'name: ' + projectData.name + ' $address: ' + projectData.address + ' $cityAndState: ' + projectData.cityAndState + ' $otherInfo: ' + projectData.otherInfo,
            architect: 'name: ' + architect.name + ' $address: ' + architect.address + ' $cityAndState: ' + architect.cityAndState + ' $otherInfo: ' + architect.otherInfo,
            box1_6_2: box1_6_2,
            box2_6_2: box2_6_2,
            box3_6_2: box3_6_2,
            workDescription: workDescription,
            box1_9_1: box1_9_1,
            box2_9_1: box2_9_1,
            box3_9_1: box3_9_1,
            box1_9_2_2: box1_9_2_2,
            box2_9_2_2: box2_9_2_2,
            field1_9_2_2: field1_9_2_2,
            field2_9_2_2: field2_9_2_2,
            field3_9_2_2: field3_9_2_2,
            portionOfWork: portionOfWork,
            substantialCompletion: substantialCompletion,
            field1_10_1: field1_10_1,
            field2_10_1: field2_10_1,
            item10_2_1: item10_2_1,
            price10_2_1: price10_2_1,
            item10_2_2: item10_2_2,
            price10_2_2: price10_2_2,
            conditionsForAcceptance10_2_2: conditionsForAcceptance10_2_2,
            item10_3: item10_3,
            unitsAndLimitations10_3: unitsAndLimitations10_3,
            pricePerUnit10_3: pricePerUnit10_3,
            item10_4: item10_4,
            price10_4: price10_4,
            field11_1_2: field11_1_2,
            field11_1_3: field11_1_3,
            field11_1_8_1: field11_1_8_1,
            field11_1_8_1_1: field11_1_8_1_1,
            field11_1_8_2: field11_1_8_2,
            field11_4: field11_4,
            field1_12_1_2: field1_12_1_2,
            field2_12_1_2: field2_12_1_2,
            field3_12_1_2: field3_12_1_2,
            field4_12_1_2: field4_12_1_2,
            field5_12_1_2: field5_12_1_2,
            field6_12_1_2: field6_12_1_2,
            field1_12_1_3: field1_12_1_3,
            field2_12_1_3: field2_12_1_3,
            field1_12_1_4: field1_12_1_4,
            field2_12_1_4: field2_12_1_4,
            field1_12_1_6: field1_12_1_6,
            field2_12_1_6: field2_12_1_6,
            field3_12_1_6: field3_12_1_6,
            field4_12_1_6: field4_12_1_6,
            field5_12_1_6: field5_12_1_6,
            field6_12_1_6: field6_12_1_6,
            field1_12_1_8: field1_12_1_8,
            field2_12_1_8: field2_12_1_8,
            field3_12_1_8: field3_12_1_8,
            field4_12_1_8: field4_12_1_8,
            field1_12_2_1: field1_12_2_1,
            field2_12_2_1: field2_12_2_1,
            field1_13_1: field1_13_1,
            field1_13_2: field1_13_2,
            field1_13_3: field1_13_3,
            contractorsRepresentative1: 'name: ' + contractorsRepresentative1.name + ' $company: ' + contractorsRepresentative1.company + ' $address: ' + contractorsRepresentative1.address + ' $cityAndState: ' + contractorsRepresentative1.cityAndState + ' $otherInfo: ' + contractorsRepresentative1.otherInfo + ' $mail: ' + contractorsRepresentative1.mail,
            contractorsRepresentative2: 'name: ' + contractorsRepresentative2.name + ' $company: ' + contractorsRepresentative2.company + ' $address: ' + contractorsRepresentative2.address + ' $cityAndState: ' + contractorsRepresentative2.cityAndState + ' $otherInfo: ' + contractorsRepresentative2.otherInfo,
            subcontractorsRepresentative: 'name: ' + subcontractorsRepresentative.name + ' $address: ' + subcontractorsRepresentative.address + ' $cityAndState: ' + subcontractorsRepresentative.cityAndState + ' $otherInfo: ' + subcontractorsRepresentative.otherInfo + ' $mail: ' + subcontractorsRepresentative.mail,
            field14_4_3: field14_4_3,
            field1_15_1: field1_15_1,
            field2_15_1: field2_15_1,
            field3_15_1: field3_15_1,
        }


        let newContract = {
            id: record !== null ? record.id : null,
            project: record !== null ? record.project : project,
            subVendor: record !== null ? record.subVendor : subVendor,
            type: contractType,
            projectNumber: projectNumber,
            dateDescription: dateDescription,
            subcontractor: subcontractor.name,
            subcontractorLocale: subcontractorLocale,
            projectName: projectName,
            projectAddress: projectAddress,
            cityStateZipCode: cityStateZipCode,
            subcontractValue: subcontractValue,
            date: moment(date).format('YYYY-MM-DD HH:mm:s'),
            sentDate: record !== null ? record.sentDate : null,
            arquive: record !== null ? record.arquive : null,
            contractSignature: record !== null ? record.contractSignature : null,
            fields: fields,
            contractFieldDTO: contractFieldDTO,
        };

        const contractValidate = {
            subVendor: newContract.subVendor !== null ? newContract.subVendor.id.toString() : '',
        };
        contractSchema.validate(contractValidate)
            .then(async () => {
                try {
                    if (record !== null) {
                        await api.put('/contracts/update', newContract);
                        message.success(t('messages:contracts.successEdit'));
                    } else {
                        await api.post('/contracts/create', newContract);
                        message.success(t('messages:contracts.success'));
                    }
                    onClose();
                } catch (error) {
                    errorHandler(error);
                }
            })
            .catch(error => {
                message.error(error.message);
                setError(error.message);
            });
        setLoading(false);
    };

    useEffect(() => {
        fetchRecords();
    }, [record]);

    return (
        <Modal
            width="95%"
            title={record !== null ? t('screens:contracts.btnEdit') : t('screens:contracts.btnNew')}
            onCancel={onClose}
            open={visible}
            loading={loading}
            footer={
                <ModalFooter onOk={handleSave} loading={loading} onCancel={onClose} cancelColor="default" />
            }
        >
            <Spin spinning={loading}>
                <Row>
                    <FormControl
                        cols={{ xs: 24, sm: 18, md: 13, lg: 11, xl: 9 }}
                        field="contractType"
                        label={t('screens:subcontractor.data.contractType')}
                    >
                        <Select
                            style={{ width: 370 }}
                            name="contractType"
                            placeholder="Select"
                            disabled={record !== null}
                            defaultValue={contractType}
                            onChange={handleChange}
                        >
                            {contractTypes?.map(item => {
                                return (
                                    <Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl
                        cols={{ xs: 24, sm: 6, md: 11, lg: 13, xl: 15 }}
                        field="subVendor"
                        label={t('screens:contracts.data.subcontractor')}
                        error={error}
                        required
                    >
                        <Select showSearch value={subcontractor.name} placeholder="Select subcontractor"
                            options={subContractorOptions}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={handleChangeSubcontractor}

                        />
                    </FormControl>
                </Row>
                <Card>
                    <Row>
                        <TextSm>
                            <b>ADDITIONS AND DELETIONS:</b> The author of this document has added information needed for its completion. The author may also have revised the text of the original AIA standard form. An Additions and Deletions Report that notes added information as well as revisions to the standard form text is available from the author and should be reviewed.
                            <br />This document has important legal consequences. Consultation with an attorney is encouraged with respect to its completion or modification.
                        </TextSm>
                    </Row>
                    <br />
                    <Row className='text-left'>
                        <Col>
                            <ImageCustom src={logo} />
                        </Col>
                        <Col flex="auto">
                            <InputTitle size="small" placeholder="AIA® Document A401® – 2017" value={contractTitle} style={{ width: '100%' }} onChange={(e) => { setContractTitle(e.target.value); }} />
                        </Col>
                    </Row>
                    <br />
                    <Title>Standard Form of Agreement Between Contractor and Subcontractor</Title>
                    <br />
                    <br />

                    <Row>
                        <Input.Group compact>
                            <b>AGREEMENT</b> made as of the
                            <InputMd size="small" placeholder="20" value={contractDate.day} style={{ width: '30px' }}
                                onChange={(e) => { setContractDate({ day: e.target.value, month: contractDate.month, year: contractDate.year }); }} />
                            th day of {' '}
                            <InputMd size="small" placeholder="September" value={contractDate.month} style={{ width: '90px' }}
                                onChange={(e) => { setContractDate({ day: contractDate.day, month: e.target.value, year: contractDate.year }); }} />
                            {' '} in the year {' '}
                            <InputMd size="small" placeholder="2025" value={contractDate.year} style={{ width: '50px' }}
                                onChange={(e) => { setContractDate({ day: contractDate.day, month: contractDate.month, year: e.target.value }); }} />
                            <br /><i>(In words, indicate day, month and year.)</i>
                            <br /><br />
                            <b>BETWEEN</b> the Contractor:
                            <br /><i>(Name, legal status, address and other information)</i>
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={contractor.name} style={{ width: '100%' }}
                                onChange={(e) => { setContractor({ name: e.target.value, address: contractor.address, cityAndState: contractor.cityAndState, otherInfo: contractor.otherInfo }) }} />
                            <br /><InputMd size="small" placeholder="address" value={contractor.address} style={{ width: '100%' }}
                                onChange={(e) => { setContractor({ name: contractor.name, address: e.target.value, cityAndState: contractor.cityAndState, otherInfo: contractor.otherInfo }) }} />
                            <br /><InputMd size="small" placeholder="city and state" value={contractor.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setContractor({ name: contractor.name, address: contractor.address, cityAndState: e.target.value, otherInfo: contractor.otherInfo }) }} />
                            <br /><InputMd size="small" placeholder="other info" value={contractor.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setContractor({ name: contractor.name, address: contractor.address, cityAndState: contractor.cityAndState, otherInfo: e.target.value }) }} />
                            <br />
                            <br />and the Subcontractor:
                            <br /><InputMd size="small" placeholder="name" value={subcontractor.name} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractor({ name: e.target.value, address: subcontractor.address, cityAndState: subcontractor.cityAndState, otherInfo: subcontractor.otherInfo }) }} />
                            <br /><InputMd size="small" placeholder="address" value={subcontractor.address} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractor({ name: subcontractor.name, address: e.target.value, cityAndState: subcontractor.cityAndState, otherInfo: subcontractor.otherInfo }) }} />
                            <br /><InputMd size="small" placeholder="city and state" value={subcontractor.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractor({ name: subcontractor.name, address: subcontractor.address, cityAndState: e.target.value, otherInfo: subcontractor.otherInfo }) }} />
                            <br /><InputMd size="small" placeholder="other info" value={subcontractor.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractor({ name: subcontractor.name, address: subcontractor.address, cityAndState: subcontractor.cityAndState, otherInfo: e.target.value }) }} />
                            <br />
                            <br />The Contractor has made a contract for construction (hereinafter, the Prime Contract) dated: <InputMd size="small" value={primeContractDate} style={{ width: '95px' }} onChange={(e) => { setPrimeContractDate(e.target.value); }} />
                            <br />
                            <br />with the Owner:
                            <br /><i>(Name, legal status, address and other information)</i>
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={owner.name} style={{ width: '100%' }}
                                onChange={(e) => { setOwner({ name: e.target.value, address: owner.address, cityAndState: owner.cityAndState, otherInfo: owner.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="address" value={owner.address} style={{ width: '100%' }}
                                onChange={(e) => { setOwner({ name: owner.name, address: e.target.value, cityAndState: owner.cityAndState, otherInfo: owner.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="city and state" value={owner.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setOwner({ name: owner.name, address: owner.address, cityAndState: e.target.value, otherInfo: owner.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="other info" value={owner.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setOwner({ name: owner.name, address: owner.address, cityAndState: owner.cityAndState, otherInfo: e.target.value }); }} />
                            <br />
                            <br />for the following Project:
                            <br /><i>(Name, location and detailed description)</i>
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={projectData.name} style={{ width: '100%' }}
                                onChange={(e) => { setProjectData({ name: e.target.value, address: projectData.address, cityAndState: projectData.cityAndState, otherInfo: projectData.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="address" value={projectData.address} style={{ width: '100%' }}
                                onChange={(e) => { setProjectData({ name: projectData.name, address: e.target.value, cityAndState: projectData.cityAndState, otherInfo: projectData.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="city and state" value={projectData.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setProjectData({ name: projectData.name, address: projectData.address, cityAndState: e.target.value, otherInfo: projectData.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="other info" value={projectData.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setProjectData({ name: projectData.name, address: projectData.address, cityAndState: projectData.cityAndState, otherInfo: e.target.value }); }} />
                            <br />
                            <br />The Prime Contract provides for the furnishing of labor, materials, equipment and services in connection with the construction of the Project. A copy of the Prime Contract, consisting of the Agreement Between Owner and Contractor (from which compensation amounts may be deleted) and the other Contract Documents enumerated therein, has been made available to the Subcontractor.
                            <br />
                            <br />The Architect for the Project:
                            <br /><i>(Name, legal status, address and other information)</i>
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={architect.name} style={{ width: '100%' }}
                                onChange={(e) => { setArchitect({ name: e.target.value, address: architect.address, cityAndState: architect.cityAndState, otherInfo: architect.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="address" value={architect.address} style={{ width: '100%' }}
                                onChange={(e) => { setArchitect({ name: architect.name, address: e.target.value, cityAndState: architect.cityAndState, otherInfo: architect.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="city and state" value={architect.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setArchitect({ name: architect.name, address: architect.address, cityAndState: e.target.value, otherInfo: architect.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="other info" value={architect.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setArchitect({ name: architect.name, address: architect.address, cityAndState: architect.cityAndState, otherInfo: e.target.value }); }} />
                            <br />
                            <br />The Contractor and the Subcontractor agree as follows.
                        </Input.Group>
                    </Row>
                    <Row style={{ lineHeight: '0.9' }}>
                        <Col flex="100%">
                            <PCustom><DividerCustom /></PCustom>
                            <TextFooter><b>{contractTitle}.</b> Copyright © 1915, 1925, 1937, 1951, 1958, 1961, 1963, 1966, 1967, 1972, 1978, 1987, 1997, 2007 and 2017 by The American Institute of Architects. All rights reserved. The “American Institute of Architects,” “AIA,” the AIA Logo, “A401,” and “AIA Contract Documents” are registered trademarks and may not be used without permission.</TextFooter>
                            <TextFooter> This draft was produced by AIA software at 13:29:16 ET on 09/22/2022 under Order No.2114321925 which expires on 06/08/2023, is not for resale, is licensed for one-time use only, and may only be used in accordance with the AIA Contract Documents® Terms of Service. To report copyright violations, e-mail copyright@aia.org.</TextFooter>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <TextFooter><b>User Notes:</b> AIA_UserNotes</TextFooter>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ textAlign: 'right' }}>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <TextFooter>{'(1448045638)'}</TextFooter>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
                <br />
                <Card>
                    <Row>
                        <Input.Group compact>
                            <Summary>TABLE OF ARTICLES</Summary>
                            <br /><br /><SummaryItem>1</SummaryItem><Summary>THE SUBCONTRACT DOCUMENTS</Summary>
                            <br /><br /><SummaryItem>2</SummaryItem><Summary>MUTUAL RIGHTS AND RESPONSIBILITIES</Summary>
                            <br /><br /><SummaryItem>3</SummaryItem><Summary>CONTRACTOR</Summary>
                            <br /><br /><SummaryItem>4</SummaryItem><Summary>SUBCONTRACTOR</Summary>
                            <br /><br /><SummaryItem>5</SummaryItem><Summary>CHANGES IN THE WORK</Summary>
                            <br /><br /><SummaryItem>6</SummaryItem><Summary>CLAIMS AND DISPUTES</Summary>
                            <br /><br /><SummaryItem>7</SummaryItem><Summary>TERMINATION, SUSPENSION OR ASSIGNMENT OF THE SUBCONTRACT</Summary>
                            <br /><br /><SummaryItem>8</SummaryItem><Summary>THE WORK OF THIS SUBCONTRACT</Summary>
                            <br /><br /><SummaryItem>9</SummaryItem><Summary>DATE OF COMMENCEMENT AND SUBSTANTIAL COMPLETION</Summary>
                            <br /><br /><SummaryItem>10</SummaryItem><Summary>SUBCONTRACT SUM</Summary>
                            <br /><br /><SummaryItem>11</SummaryItem><Summary>PAYMENTS</Summary>
                            <br /><br /><SummaryItem>12</SummaryItem><Summary>INSURANCE AND BONDS</Summary>
                            <br /><br /><SummaryItem>13</SummaryItem><Summary>TEMPORARY FACILITIES, SERVICES, EQUIPMENT AND WORKING CONDITIONS</Summary>
                            <br /><br /><SummaryItem>14</SummaryItem><Summary>MISCELLANEOUS PROVISIONS</Summary>
                            <br /><br /><SummaryItem>15</SummaryItem><Summary>ENUMERATION OF SUBCONTRACT DOCUMENTS</Summary>

                            <br /><br /><Article>ARTICLE 1</Article><b>THE SUBCONTRACT DOCUMENTS</b>
                            <br /><b>§ 1.1</b> The Subcontract Documents consist of (1) this Agreement; (2) the Prime Contract, consisting of the Agreement between the Owner and Contractor and the other Contract Documents enumerated therein; (3) Modifications to the Prime Contract, whether issued before or after the execution of this Agreement, in accordance with the provisions of Article 5; (4) other documents listed in Article 15 of this Agreement; and (5) Modifications to this Subcontract issued after execution of this Agreement, in accordance with the provisions of Article 5. These form the Subcontract, and are as fully a part of the Subcontract as if attached to this Agreement or repeated herein.
                            <br /><br /><b>§ 1.2</b> The Subcontract Documents form the Subcontract for Construction. The Subcontract represents the entire and integrated agreement between the parties hereto and supersedes prior negotiations, representations, or agreements, either written or oral. An enumeration of the Subcontract Documents, other than Modifications to the Prime Contract or Modifications to this Subcontract issued subsequent to the execution of this Agreement, appears in Article 15.
                            <br /><br /><b>§ 1.3</b> Except to the extent of a conflict with a specific term or condition contained in the Subcontract Documents, the General Conditions governing this Subcontract shall be the AIA Document A201™–2017, General Conditions of the Contract for Construction.
                            <br /><br /><b>§ 1.4</b> The Subcontract may be amended or modified only by a Modification to this Subcontract. A Modification to this Subcontract is a written amendment to this Agreement signed by both parties, or as otherwise described in, and in accordance with the provisions of, Article 5.
                            <br /><br /><b>§ 1.5</b> The Subcontract Documents shall not be construed to create a contractual relationship of any kind (1) between the Architect and the Subcontractor, (2) between the Owner and the Subcontractor, or (3) between any persons or entities other than the Contractor and Subcontractor.
                            <br /><br /><b>§ 1.6</b> The Contractor shall make the Subcontract Documents available to the Subcontractor prior to execution of this Agreement, and thereafter, upon request. The Contractor may charge the Subcontractor for the reasonable cost to reproduce the Subcontract Documents provided to the Subcontractor.

                            <br /><br /><Article>ARTICLE 2</Article><b>MUTUAL RIGHTS AND RESPONSIBILITIES</b>
                            <br />The Contractor and Subcontractor shall be mutually bound by the terms of this Agreement and, to the extent that the provisions of AIA Document A201–2017 apply to this Agreement pursuant to Section 1.3 and provisions of the Prime Contract apply to the Work of the Subcontractor, the Contractor shall assume toward the Subcontractor all obligations and responsibilities that the Owner, under such documents, assumes toward the Contractor, and the Subcontractor shall assume toward the Contractor all obligations and responsibilities that the Contractor, under such documents, assumes toward the Owner and the Architect. The Contractor shall have the benefit of all rights, remedies, and redress against the Subcontractor that the Owner, under such documents, has against the Contractor, and the Subcontractor shall have the benefit of all rights, remedies, and redress against the Contractor that the Contractor, under such documents, has against the Owner, insofar as applicable to this Subcontract. Where a provision of such documents is inconsistent with a provision of this Agreement, this Agreement shall govern.

                            <br /><br /><Article>ARTICLE 3</Article><b>CONTRACTOR</b>
                            <br /><b>§ 3.1 General</b>
                            <br /><b>§ 3.1.1</b> The Contractor is the person or entity identified as such in this Agreement and is referred to throughout the Subcontract Documents as if singular in number. The Contractor shall designate in writing a representative who shall have express authority to bind the Contractor with respect to all Project matters requiring the Contractor’s approval or authorization. The term “Contractor” means the Contractor or the Contractor’s authorized representative.
                            <br /><br /><b>§ 3.1.2</b> The Contractor shall render decisions in a timely manner and in accordance with the Contractor’s construction schedule.
                            <br /><br /><b>§ 3.2 Services Provided by the Contractor</b>
                            <br /><b>§ 3.2.1</b> The Contractor shall cooperate with the Subcontractor in scheduling and performing the Contractor’s Work to avoid conflicts or interference in the Subcontractor’s Work and shall review, and expedite written responses to, submittals made by the Subcontractor in accordance with Section 4.2.3 and Article 5. Promptly after execution of this Agreement, the Contractor shall provide the Subcontractor with copies of the Contractor’s construction schedule and schedule of submittals, together with such additional scheduling details as will enable the Subcontractor to plan and perform the Subcontractor’s Work properly. The Contractor shall promptly notify the Subcontractor of subsequent changes in the construction and submittal schedules and additional scheduling details.
                            <br /><br /><b>§ 3.2.2</b> The Contractor shall provide suitable areas for storage of the Subcontractor’s materials and equipment during the course of the Work. Except as previously agreed upon, additional costs to the Subcontractor resulting from relocation of such storage areas at the direction of the Contractor shall be reimbursed by the Contractor.
                            <br /><br /><b>§ 3.3 Communications</b>
                            <br /><b>§ 3.3.1</b> The Contractor shall promptly make available to the Subcontractor information, including information received from the Owner, that affects the performance of this Subcontract and that becomes available to the Contractor subsequent to execution of this Subcontract.
                            <br /><br /><b>§ 3.3.2</b> The Contractor shall not give instructions or orders directly to the Subcontractor’s employees or to the Subcontractor’s Sub-subcontractors or suppliers unless such persons are designated as authorized representatives of the Subcontractor.
                            <br /><br /><b>§ 3.3.3</b> The Contractor shall permit the Subcontractor to request information directly from the Architect regarding the percentages of completion and the amount certified on account of Work done by the Subcontractor.
                            <br /><br /><b>§ 3.3.4</b> If hazardous materials or substances are being used on the site by the Contractor, a subcontractor, or anyone directly or indirectly employed by them (other than the Subcontractor), and they are a type of hazardous material or substance of which an employer is required by law to notify its employees, the Contractor shall, prior to delivery to the Project site or exposure of the Subcontractor’s employees to such material or substance, give notice of the chemical composition thereof to the Subcontractor in sufficient detail and time to permit the Subcontractor’s compliance with such laws.
                            <br /><br /><b>§ 3.3.5</b> The Contractor shall promptly notify the Subcontractor of any fault or defect in the Work under this Subcontract or nonconformity with the Subcontract Documents.
                            <br /><br /><b>§ 3.3.6</b> The Contractor shall furnish to the Subcontractor within 30 days after receipt of a written request, or earlier if so required by law, information necessary and relevant for the Subcontractor to evaluate, give notice of or enforce mechanic’s lien rights. Such information shall include a correct statement of the record legal title to the property, usually referred to as the site, on which the Project is located and the Owner’s interest therein. If the Contractor does not have such information, the Contractor shall request the information from the Owner in accordance with Article 2 of AIA Document A201-2017 and promptly furnish the information received from the Owner to the Subcontractor.
                            <br /><br /><b>§ 3.3.7</b> If the Contractor asserts a Claim against, or defends a Claim by, the Owner that relates to the Work of the Subcontractor, the Contractor shall promptly make available to the Subcontractor all information relating to the portion of the Claim that relates to the Work of the Subcontractor.
                            <br /><br /><b>§ 3.4 Claims by the Contractor</b>
                            <br /><b>§ 3.4.1</b> Liquidated damages, if provided for in the Prime Contract, shall be assessed against the Subcontractor only to the extent caused by the Subcontractor or any person or entity for whose acts the Subcontractor may be liable, and in no case for delays or causes arising outside the scope of this Subcontract.
                            <br /><br /><b>§ 3.4.2</b> The Contractor’s Claims for the costs of services or materials provided due to the Subcontractor’s failure to execute the Work shall require
                            <br />
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>seven days’ notice prior to the Contractor’s providing services or materials, except in an emergency;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}> </Item>
                                        <td>and</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>written compilations to the Subcontractor of services and materials provided by the Contractor and charges for such services and materials no later than the fifteenth day of the month following the Contractor’s providing such services or materials.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 3.5 Contractor’s Remedies</b>
                            <br />If the Subcontractor defaults or neglects to carry out the Work in accordance with this Agreement and fails within five working days after receipt of notice from the Contractor to commence and continue correction of such default or neglect with diligence and promptness, the Contractor may, without prejudice to other remedies the Contractor may have, remedy such default or neglect and withhold, in accordance with Section 11.1.7.2, the reasonable cost thereof from current or future payments due the Subcontractor. If payments due to the Subcontractor are not sufficient to cover such amounts, the Subcontractor shall pay the difference to the Contractor.

                            <br /><br /><Article>ARTICLE 4</Article><b>SUBCONTRACTOR</b>
                            <br /><b>§ 4.1 General</b>
                            <br />The Subcontractor is the person or entity identified as such in this Agreement and is referred to throughout the Subcontract Documents as if singular in number. The Subcontractor shall be lawfully licensed, if required in the jurisdiction where the Project is located. The Subcontractor shall designate in writing a representative who shall have express authority to act on the Subcontractor’s behalf with respect to the Project. The term “Subcontractor” means the Subcontractor or the Subcontractor’s authorized representative.
                            <br /><br /><b>§ 4.2 Execution and Progress of the Work</b>
                            <br /><b>§ 4.2.1</b> For all Work the Subcontractor intends to subcontract, the Subcontractor shall enter into written agreements with Sub-subcontractors performing portions of the Work of this Subcontract by which the Subcontractor and the Sub-subcontractor are mutually bound, to the extent of the Work to be performed by the Sub-subcontractor, assuming toward each other all obligations and responsibilities that the Contractor and Subcontractor assume toward each other and having the benefit of all rights, remedies and redress each against the other that the Contractor and Subcontractor have by virtue of the provisions of this Agreement.
                            <br /><br /><b>§ 4.2.2</b> The Subcontractor shall supervise and direct the Subcontractor’s Work, and shall cooperate with the Contractor in scheduling and performing the Subcontractor’s Work to avoid conflict, delay in, or interference with the Work of the Contractor, other subcontractors, the Owner, or Separate Contractors.
                            <br /><br /><b>§ 4.2.3 Submittals</b>
                            <br /><b>§ 4.2.3.1</b> The Subcontractor shall submit Shop Drawings, Product Data, Samples, and similar submittals required by the Subcontract Documents with reasonable promptness and in such sequence as to cause no delay in the Work or in the activities of the Contractor or other subcontractors.
                            <br /><br /><b>§ 4.2.3.2</b> By submitting Shop Drawings, Product Data, Samples, and similar submittals, the Subcontractor represents to the Contractor that the Subcontractor has (1) reviewed and approved them; (2) determined and verified materials, field measurements, and field construction criteria related thereto, or will do so; and (3) checked and coordinated the information contained within such submittals with the requirements of the Work and of the Subcontract Documents.
                            <br /><br /><b>§ 4.2.4</b> The Subcontractor shall furnish to the Contractor periodic progress reports on the Work of this Subcontract as mutually agreed, including information on the status of materials and equipment that may be in the course of preparation, manufacture, or transit.
                            <br /><br /><b>§ 4.2.5</b> The Subcontractor agrees that the Contractor and the Architect each have the authority to reject Work of the Subcontractor that does not conform to the Prime Contract. The Architect’s decisions on matters relating to aesthetic effect shall be final and binding on the Subcontractor if consistent with the intent expressed in the Prime Contract.
                            <br /><br /><b>§ 4.2.6</b> The Subcontractor shall pay for all materials, equipment, and labor used in connection with the performance of this Subcontract through the period covered by previous payments received from the Contractor, and shall furnish satisfactory evidence, when requested by the Contractor, to verify compliance with the above requirements.
                            <br /><br /><b>§ 4.2.7</b> The Subcontractor shall take necessary precautions to properly protect the work of the Contractor, Separate Contractors, and other subcontractors from damage caused by operations under this Subcontract.
                            <br /><br /><b>§ 4.2.8</b> The Subcontractor shall cooperate with the Contractor, other subcontractors, the Owner, and Separate Contractors whose work might affect the Subcontractor’s Work. The Subcontractor shall participate in the preparation of coordinated drawings in areas of congestion, if required by the Prime Contract, specifically noting and advising the Contractor of potential conflicts between the Work of the Subcontractor and that of the Contractor, other subcontractors, the Owner, or Separate Contractors.
                            <br /><br /><b>§ 4.3 Permits, Fees, Notices, and Compliance with Laws</b>
                            <br /><b>§ 4.3.1</b> The Subcontractor shall give notices and comply with applicable laws, statutes, ordinances, codes, rules and regulations, and lawful orders of public authorities bearing on performance of the Work of this Subcontract. The Subcontractor shall secure and pay for permits, fees, licenses, and inspections by government agencies necessary for proper execution and completion of the Subcontractor’s Work, the furnishing of which is required of the Contractor by the Prime Contract.
                            <br /><br /><b>§ 4.3.2</b> The Subcontractor shall comply with Federal, state, and local tax laws; social security acts; unemployment compensation acts; and workers’ compensation acts, insofar as applicable to the performance of this Subcontract.
                            <br /><br /><b>§ 4.4 Safety Precautions and Procedures</b>
                            <br /><b>§ 4.4.1</b> The Subcontractor shall take reasonable safety precautions with respect to performance of this Subcontract. The Subcontractor shall comply with safety measures initiated by the Contractor and with applicable laws, statutes, ordinances, codes, rules and regulations, and lawful orders of public authorities, for the safety of persons and property, in accordance with the requirements of the Prime Contract. The Subcontractor shall notify the Contractor within three days of an injury to an employee or agent of the Subcontractor which occurred at the site.
                            <br /><br /><b>§ 4.4.2</b> If hazardous materials or substances are being used on the site by the Subcontractor, the Subcontractor’s Sub-subcontractors, or anyone directly or indirectly employed by them, and they are a type of hazardous material or substance of which an employer is required by law to notify its employees, the Subcontractor shall, prior to delivery to the Project site or exposure of the Contractor, other subcontractors, and other employers on the site to such material or substance, give notice of the chemical composition thereof to the Contractor in sufficient detail and time to permit compliance with the laws by the Contractor, other subcontractors, and other employers on the site.
                            <br /><br /><b>§ 4.4.3</b> If reasonable precautions will be inadequate to prevent foreseeable bodily injury or death to persons resulting from a hazardous material or substance, including but not limited to asbestos or polychlorinated biphenyl (PCB), encountered on the site by the Subcontractor, the Subcontractor shall, upon recognizing the condition, immediately stop Work in the affected area and promptly report the condition to the Contractor in writing. When the material or substance has been rendered harmless, the Subcontractor’s Work in the affected area shall resume upon written agreement of the Contractor and Subcontractor. The Subcontract Time shall be extended appropriately and the Subcontract Sum shall be increased in the amount of the Subcontractor’s reasonable additional costs of demobilization, delay, and remobilization, which adjustments shall be accomplished as provided in Article 5 of this Agreement.
                            <br /><br /><b>§ 4.4.4</b> To the fullest extent permitted by law, the Contractor shall indemnify and hold harmless the Subcontractor, the Subcontractor’s Sub-subcontractors, and agents and employees of any of them from and against claims, damages, losses, and expenses, including but not limited to attorneys’ fees, arising out of or resulting from performance of the Work in the affected area if in fact the material or substance presents the risk of bodily injury or death as described in Section 4.4.3 and has not been rendered harmless, provided that such claim, damage, loss, or expense is attributable to bodily injury, sickness, disease or death, or to injury to or destruction of tangible property (other than the Work itself) except to the extent that such damage, loss, or expense is due to the fault or negligence of the party seeking indemnity.
                            <br /><br /><b>§ 4.4.5</b> The Subcontractor shall reimburse the Contractor for the cost and expense the Contractor incurs (1) for remediation of a hazardous material or substance brought to the site and negligently handled by the Subcontractor or (2) where the Subcontractor fails to perform its obligations under Section 4.4.3, except to the extent that the cost and expense are due to the Contractor’s fault or negligence.
                            <br /><br /><b>§ 4.5 Cleaning Up</b>
                            <br /><b>§ 4.5.1</b> The Subcontractor shall keep the premises and surrounding area free from accumulation of waste materials or rubbish caused by operations performed under this Subcontract. The Subcontractor shall not be held responsible for conditions caused by other contractors or subcontractors.
                            <br /><br /><b>§ 4.5.2</b> As provided under Section 3.4.2, if the Subcontractor fails to clean up as provided in the Subcontract Documents, the Contractor may charge the Subcontractor for the Subcontractor’s appropriate share of cleanup costs.
                            <br /><br /><b>§ 4.6 Warranty</b>
                            <br /><b>§ 4.6.1</b> The Subcontractor warrants to the Owner, Architect, and Contractor that materials and equipment furnished under this Subcontract will be of good quality and new unless the Subcontract Documents require or permit otherwise. The Subcontractor further warrants that the Work will conform to the requirements of the Subcontract Documents and will be free from defects, except for those inherent in the quality of the Work the Subcontract Documents require or permit. Work, materials, or equipment not conforming to these requirements may be considered defective. The Subcontractor’s warranty excludes remedy for damage or defect caused by abuse, alterations to the Work not executed by the Subcontractor, improper or insufficient maintenance, improper operation, or normal wear and tear under normal usage. If required by the Architect and Contractor, the Subcontractor shall provide satisfactory evidence as to the kind and quality of materials and equipment furnished or to be furnished.
                            <br /><br /><b>§ 4.6.2</b> All material, equipment, or other special warranties required by the Subcontract Documents shall be issued in the name of the Owner, or shall be transferable to the Owner, and shall commence in accordance with the Subcontract Documents.
                            <br /><br /><b>§ 4.7 Indemnification</b>
                            <br /><b>§ 4.7.1</b> To the fullest extent permitted by law, the Subcontractor shall indemnify and hold harmless the Owner, Contractor, Architect, Architect’s consultants, and agents and employees of any of them from and against claims, damages, losses, and expenses, including but not limited to attorney’s fees, arising out of or resulting from performance of the Subcontractor’s Work under this Subcontract, provided that any such claim, damage, loss, or expense is attributable to bodily injury, sickness, disease or death, or to injury to or destruction of tangible property (other than the Work itself), but only to the extent caused by the negligent acts or omissions of the Subcontractor, the Subcontractor’s Sub-subcontractors, anyone directly or indirectly employed by them, or anyone for whose acts they may be liable, regardless of whether or not such claim, damage, loss, or expense is caused in part by a party indemnified hereunder. Such obligation shall not be construed to negate, abridge, or otherwise reduce other rights or obligations of indemnity which would otherwise exist as to a party or person described in this Section 4.7.
                            <br /><br /><b>§ 4.7.2</b> In claims against any person or entity indemnified under this Section 4.7 by an employee of the Subcontractor, the Subcontractor’s Sub-subcontractors, anyone directly or indirectly employed by them, or anyone for whose acts they may be liable, the indemnification obligation under Section 4.7.1 shall not be limited by a limitation on the amount or type of damages, compensation or benefits payable by or for the Subcontractor, or the Subcontractor’s Sub-subcontractors under workers’ compensation acts, disability benefit acts, or other employee benefit acts.
                            <br /><br /><b>§ 4.8 Remedies for Nonpayment</b>
                            <br />If the Contractor does not pay the Subcontractor through no fault of the Subcontractor, within seven days from the time payment should be made as provided in this Agreement, the Subcontractor may, without prejudice to any other available remedies, upon seven additional days’ notice to the Contractor, stop the Work of this Subcontract until payment of the amount owing has been received. The Subcontract Sum shall, by appropriate Modification, be increased by the amount of the Subcontractor’s reasonable costs of demobilization, delay, and remobilization.
                            <br /><br /><b>§ 4.9 Professional Services Provided by Subcontractor</b>
                            <br /><b>§ 4.9.1</b> The Subcontractor shall not be required to provide professional services that constitute the practice of architecture or engineering unless such services are specifically required by the Subcontract Documents or unless the Subcontractor is required to provide such services in order to carry out the Subcontractor's responsibilities for its own construction means, methods, techniques, sequences, and procedures. The Subcontractor shall not be required to provide professional services in violation of applicable law.
                            <br /><br /><b>§ 4.9.2</b> If professional design services or certifications by a design professional related to systems, materials, or equipment are specifically required of the Subcontractor by the Subcontract Documents, the Contractor will provide all performance and design criteria that such services must satisfy to the extent the Contractor has received such performance and design criteria from the Owner and Architect under the terms of the Prime Contract.
                            <br /><br /><b>§ 4.9.3</b> If professional design services or certifications by a design professional are required because of means, methods, techniques, sequences, or procedures required by the Contractor and related to the Work of the Subcontractor, the Contractor will provide all performance and design criteria that such services must satisfy.
                            <br /><br /><b>§ 4.9.4</b> The Subcontractor shall be entitled to rely upon the adequacy, accuracy, and completeness of the performance and design criteria received from the Contractor under this Section 4.9.
                            <br /><br /><b>§ 4.9.5</b> The Subcontractor shall cause the professional services performed under this Section 4.9 to be provided by a properly licensed design professional, whose signature and seal shall appear on all drawings, calculations, specifications, certifications, Shop Drawings, and other submittals prepared by such professional. Shop drawings and other submittals related to the Work designed by such design professional shall bear the professional's written approval when submitted to the Contractor. The Contractor shall be entitled to rely upon the adequacy, accuracy, and completeness of the services, certifications, and approvals performed or provided by the design professionals, provided the Contractor has provided to the Subcontractor all performance and design criteria required by this Section 4.9.

                            <br /><br /><Article>ARTICLE 5</Article><b>CHANGES IN THE WORK</b>
                            <br /><b>§ 5.1</b> The Owner may make changes in the Work by issuing Modifications to the Prime Contract. Upon receipt of a Modification to the Prime Contract issued subsequent to the execution of this Agreement, the Contractor shall promptly notify the Subcontractor of such Modification. Unless otherwise directed by the Contractor, the Subcontractor shall not thereafter order materials or perform Work that would be inconsistent with the changes made by the Modification to the Prime Contract.
                            <br /><br /><b>§ 5.2</b> The Subcontractor may be ordered in writing by the Contractor, without invalidating this Subcontract, to make changes in the Work within the general scope of this Subcontract consisting of additions, deletions, or other revisions, including those required by Modifications to the Prime Contract issued subsequent to the execution of this Agreement, with the Subcontract Sum and the Subcontract Time adjusted accordingly. The Subcontractor, prior to the commencement of such changed or revised Work, shall submit promptly to the Contractor written copies of a Claim for adjustment to the Subcontract Sum and Subcontract Time for such revised Work in a manner consistent with requirements of the Subcontract Documents.
                            <br /><br /><b>§ 5.3</b> The Subcontractor shall make all Claims promptly to the Contractor for additional cost, extensions of time and damages for delays, or other causes in accordance with the Subcontract Documents. A Claim which will affect or become part of a Claim which the Contractor is required to make under the Prime Contract within a specified time period or in a specified manner shall be made in sufficient time to permit the Contractor to satisfy the requirements of the Prime Contract. Such Claims shall be received by the Contractor not less than two working days preceding the time by which the Contractor’s Claim must be made. Failure of the Subcontractor to make such a timely Claim shall bind the Subcontractor to the same consequences as those to which the Contractor is bound.

                            <br /><br /><Article>ARTICLE 6</Article><b>CLAIMS AND DISPUTES</b>
                            <br /><b>§ 6.1 Mediation</b>
                            <br /><b>§ 6.1.1</b> Claims, disputes, or other matters in controversy arising out of or related to this Subcontract, except those waived as provided for in Sections 6.4 and 11.3.2, shall be subject to mediation as a condition precedent to binding dispute resolution.
                            <br /><br /><b>§ 6.1.2</b> The parties shall endeavor to resolve their Claims by mediation which, unless the parties mutually agree otherwise, shall be administered by the American Arbitration Association in accordance with its Construction Industry Mediation Procedures in effect on the date of the Agreement. A request for mediation shall be made in writing, delivered to the other party to this Subcontract and filed with the person or entity administering the mediation. The request may be made concurrently with the filing of binding dispute resolution proceedings but, in such event, mediation shall proceed in advance of binding dispute resolution proceedings, which shall be stayed pending mediation for a period of 60 days from the date of filing, unless stayed for a longer period by agreement of the parties or court order. If an arbitration is stayed pursuant to this Section 6.1.2, the parties may nonetheless proceed to the selection of the arbitrators(s) and agree upon a schedule for later proceedings.
                            <br /><br /><b>§ 6.1.3</b> The parties shall share the mediator’s fee and any filing fees equally. The mediation shall be held in the place where the Project is located, unless another location is mutually agreed upon. Agreements reached in mediation shall be enforceable as settlement agreements in any court having jurisdiction thereof.
                            <br /><br /><b>§ 6.2 Binding Dispute Resolution</b>
                            <br />For any Claim subject to, but not resolved by mediation pursuant to Section 6.1, the method of binding dispute resolution shall be as follows:
                            <br /><i>(Check the appropriate box.)</i>
                            <br /><br />
                            <Row>
                                <Col span={24}><Checkbox checked={box1_6_2} onChange={(e) => { setBox1_6_2(e.target.checked) }}>Arbitration pursuant to Section 6.3 of this Agreement</Checkbox></Col>
                                <Col span={24}><Checkbox checked={box2_6_2} onChange={(e) => { setBox2_6_2(e.target.checked) }}>Litigation in a court of competent jurisdiction</Checkbox></Col>
                                <Col span={24}><Checkbox checked={box3_6_2} onChange={(e) => { setBox3_6_2(e.target.checked) }}>Other: <i>(Specify)</i>
                                    <br /><br />Mediation: If the parties are unable to resolve a dispute arising under this Agreement, the parties agree, as a precondition to arbitration, to submit the dispute to pre-suit mediation; provided, however: (i) the mediation shall be conducted in Sacramento, California, before a mutually acceptable California Supreme Court certified mediator and at a mutually agreed time and place; (ii) the parties shall endeavor to schedule mediation of the dispute within forty-five (45) of one-party receiving notice from the other party of a dispute that cannot be resolved, such 45-day period depending on the schedules and availability of the mediator, parties, witnesses, investigators, and experts; and (iii) the mediator’s fee and any mediation fees imposed by the California Supreme Court shall be divided equally between the parties</Checkbox>
                                </Col>
                            </Row>
                            <br /><br />If the Contractor and Subcontractor do not select a method of binding dispute resolution, or do not subsequently agree in writing to a binding dispute resolution method other than litigation, Claims will be resolved by litigation in a court of competent jurisdiction.
                            <br /><br /><b>§ 6.3 Arbitration</b>
                            <br /><b>§ 6.3.1</b> If the Contractor and Subcontractor have selected arbitration as the method of binding dispute resolution in Section 6.2, any Claim subject to, but not resolved by, mediation shall be subject to arbitration which, unless the parties mutually agree otherwise, shall be administered by the American Arbitration Association in accordance with its Construction Industry Arbitration Rules in effect on the date of this Agreement. The arbitration should be conducted in the place where the Project is located, unless another location is mutually agreed upon. A demand for arbitration shall be made in writing, delivered to the other party to the Subcontract, and filed with the person or entity administering the arbitration. The party filing a notice of demand for arbitration must assert in the demand all Claims then known to that party on which arbitration is permitted to be demanded.
                            <br /><br /><b>§ 6.3.2</b> A demand for arbitration shall be made no earlier than concurrently with the filing of a request for mediation but in no event shall it be made after the date when the institution of legal or equitable proceedings based on the Claim would be barred by the applicable statute of limitations. For statute of limitations purposes, receipt of a written demand for arbitration by the person or entity administering the arbitration shall constitute the institution of legal or equitable proceedings based on the Claim.
                            <br /><br /><b>§ 6.3.3</b> The award rendered by the arbitrator or arbitrators shall be final, and judgment may be entered upon it in accordance with applicable law in any court having jurisdiction thereof.
                            <br /><br /><b>§ 6.3.4</b> The foregoing agreement to arbitrate, and other agreements to arbitrate with an additional person or entity duly consented to by parties to the Agreement, shall be specifically enforceable under applicable law in any court having jurisdiction thereof.
                            <br /><br /><b>§ 6.3.5 Consolidation or Joinder</b>
                            <br /><b>§ 6.3.5.1</b> Subject to the rules of the American Arbitration Association or other applicable arbitration rules, either party may consolidate an arbitration conducted under this Agreement with any other arbitration to which it is a party provided that (1) the arbitration agreement governing the other arbitration permits consolidation; (2) the arbitrations to be consolidated substantially involve common questions of law or fact; and (3) the arbitrations employ materially similar procedural rules and methods for selecting arbitrator(s).
                            <br /><br /><b>§ 6.3.5.2</b> Subject to the rules of the American Arbitration Association or other applicable arbitration rules, either party may include by joinder persons or entities substantially involved in a common question of law or fact whose presence is required if complete relief is to be accorded in arbitration, provided that the party sought to be joined consents in writing to such joinder. Consent to arbitration involving an additional person or entity shall not constitute consent to arbitration of a Claim, dispute, or other matter in question not described in the written consent.
                            <br /><br /><b>§ 6.3.5.3</b> The Contractor and Subcontractor grant to any person or entity made a party to an arbitration conducted under this Section 6.3, whether by joinder or consolidation, the same rights of joinder and consolidation as the Contractor and Subcontractor under this Agreement.
                            <br /><br /><b>§ 6.4 Waiver of Claims for Consequential Damages</b>
                            <br />The Contractor and Subcontractor waive claims against each other for consequential damages arising out of or relating to this Subcontract, including without limitation, any consequential damages due to either party’s termination in accordance with Article 7. Nothing contained herein shall be deemed to preclude an award of liquidated damages, when applicable, in accordance with the requirements of this Agreement.

                            <br /><br /><Article>ARTICLE 7</Article><b>TERMINATION, SUSPENSION OR ASSIGNMENT OF THE SUBCONTRACT</b>
                            <br /><b>§ 7.1 Termination by the Subcontractor</b>
                            <br />The Subcontractor may terminate the Subcontract for the same reasons and under the same circumstances and procedures with respect to the Contractor as the Contractor may terminate with respect to the Owner under the Prime Contract, or for nonpayment of amounts due under this Subcontract for 60 days or longer. In the event of such termination by the Subcontractor for any reason which is not the fault of the Subcontractor, the Subcontractor’s Sub-subcontractors, or their agents or employees or other persons or entities performing portions of the Work under contract with the Subcontractor, the Subcontractor shall be entitled to recover from the Contractor payment for Work executed and for proven loss with respect to materials, equipment, tools, and construction equipment and machinery, as well as reasonable overhead and profit on work not executed and costs incurred by reason of such termination.
                            <br /><br /><b>§ 7.2 Termination by the Contractor</b>
                            <br /><b>§ 7.2.1 Termination for Cause</b>
                            <br />If the Subcontractor repeatedly fails or neglects to carry out the Work in accordance with the Subcontract Documents or otherwise to perform in accordance with this Subcontract and fails within a ten-day period after receipt of notice to commence and continue correction of such default or neglect with diligence and promptness, the Contractor may, by notice to the Subcontractor and without prejudice to any other remedy the Contractor may have, terminate the Subcontract and finish the Subcontractor’s Work by whatever method the Contractor may deem expedient. If the unpaid balance of the Subcontract Sum exceeds the expense of finishing the Subcontractor’s Work and other damages incurred by the Contractor and not expressly waived, such excess shall be paid to the Subcontractor. If such expense and damages exceed the unpaid balance of the Subcontract Sum, the Subcontractor shall pay the difference to the Contractor.
                            <br /><br /><b>§ 7.2.2 Termination for Convenience</b>
                            <br /><b>§ 7.2.2.1</b> If the Owner terminates the Prime Contract for the Owner’s convenience, the Contractor shall promptly deliver notice to the Subcontractor.
                            <br /><br /><b>§ 7.2.2.2</b> In case of such termination for the Owner’s convenience, the Subcontractor shall be entitled to receive payment for Work properly executed, costs incurred by reason of the termination, and reasonable overhead and profit on the Work not executed.
                            <br /><br /><b>§ 7.2.2.3</b> Upon receipt of notice of termination, the Subcontractor shall
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>cease operations as directed by the Contractor in the notice;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>take actions necessary, or that the Contractor may direct, for the protection and preservation of the Work;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}> </Item>
                                        <td>and</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.3</Item>
                                        <td>except for Work directed to be performed prior to the effective date of termination stated in the notice, terminate all existing Sub-subcontracts and purchase orders and enter into no further Sub-subcontracts and purchase orders.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 7.3 Suspension by the Contractor for Convenience</b>
                            <br /><b>§ 7.3.1</b> The Contractor may, without cause, order the Subcontractor in writing to suspend, delay, or interrupt the Work of this Subcontract in whole or in part for such period of time as the Contractor may determine. In the event of suspension ordered by the Contractor, the Subcontractor shall be entitled to an equitable adjustment of the Subcontract Time and Subcontract Sum.
                            <br /><br /><b>§ 7.3.2</b> The Subcontract Time and Subcontract Sum shall be adjusted for increases in the cost and time caused by suspension, delay or interruption as described in Section 7.3.1. Adjustment of the Subcontract Sum shall include profit on the increased cost of performance caused by suspension, delay, or interruption. No adjustment shall be made to the extent that
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>performance is, was or would have been so suspended, delayed, or interrupted by another cause for which the Subcontractor is responsible;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}> </Item>
                                        <td>or</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>an equitable adjustment is made or denied under another provision of this Subcontract.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 7.4 Assignment of the Subcontract</b>
                            <br /><b>§ 7.4.1</b> In the event the Owner terminates the Prime Contract for cause, this Subcontract is assigned to the Owner pursuant to Section 5.4 of AIA Document A201–2017 provided the Owner accepts the assignment by notifying the Contractor and Subcontractor.
                            <br /><br /><b>§ 7.4.2</b> Without the Contractor’s written consent, the Subcontractor shall not assign the Work of this Subcontract, subcontract the whole of this Subcontract, or subcontract portions of this Subcontract.

                            <br /><br /><Article>ARTICLE 8</Article><b>THE WORK OF THIS SUBCONTRACT</b>
                            <br />The Subcontractor shall execute the following portion of the Work described in the Subcontract Documents, including all labor, materials, equipment, services and other items required to complete such portion of the Work, except to the extent specifically indicated in the Subcontract Documents to be the responsibility of others.
                            <br /><i>(Insert a precise description of the Work of this Subcontract, referring where appropriate to numbers of Drawings, sections of Specifications and pages of Addenda, Modifications, and accepted alternates.)</i>
                            <br /><br /><InputMd size="small" value={workDescription} style={{ width: '100%' }} onChange={(e) => { setWorkDescription(e.target.value); }} />

                            <br /><br /><Article>ARTICLE 9</Article><b>DATE OF COMMENCEMENT AND SUBSTANTIAL COMPLETION</b>
                            <br /><b>§ 9.1</b> The date of commencement of the Subcontractor’s Work, shall be:
                            <br /><i>(Check one of the following boxes.)</i>
                            <br /><br />
                            <Row>
                                <Col span={24}><Checkbox checked={box1_9_1} onChange={(e) => { setBox1_9_1(e.target.checked) }}>The date of this Agreement.</Checkbox></Col>
                                <Col span={24}><Checkbox checked={box2_9_1} onChange={(e) => { setBox2_9_1(e.target.checked) }}>A date set forth in a notice to proceed issued by the Contractor.</Checkbox></Col>
                                <Col span={24}><Checkbox checked={box3_9_1} onChange={(e) => { setBox3_9_1(e.target.checked) }}>Established as follows:
                                    <br /><i>(Insert a date or a means to determine the date of commencement of the Subcontractor’s Work.)</i></Checkbox>
                                </Col>
                            </Row>
                            <br /><br />If a date of commencement of the Subcontractor’s Work is not selected, then the date of commencement shall be the date of this Agreement.
                            <br /><br /><b>§ 9.2 Subcontract Time</b>
                            <br /><b>§ 9.2.1</b> The Subcontract Time is the period of time, including authorized adjustments, allotted in the Subcontract Documents for Substantial Completion of the Work described in the Subcontract Documents. The Subcontract Time shall be measured from the date of commencement of the Subcontractor’s Work.
                            <br /><br /><b>§ 9.2.2</b> Subject to adjustments of the Subcontract Time as provided in the Subcontract Documents, the Subcontractor shall achieve substantial completion of the Subcontractor’s Work:
                            <br /><i>(Check one of the following boxes and complete the necessary information.)</i>
                            <br /><br />
                            <Row>
                                <Col span={24}>
                                    <Checkbox checked={box1_9_2_2} onChange={(e) => { setBox1_9_2_2(e.target.checked) }}>Not later than {' '}
                                        <InputMd size="small" value={field1_9_2_2} style={{ width: '40px' }} onChange={(e) => { setField1_9_2_2(e.target.value); }} />
                                        {' '}(<InputMd size="small" value={field2_9_2_2} style={{ width: '40px' }} onChange={(e) => { setField2_9_2_2(e.target.value); }} />) calendar days from the date of commencement of the Subcontractor’s Work.
                                    </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox checked={box2_9_2_2} onChange={(e) => { setBox2_9_2_2(e.target.checked) }}>By the following date:</Checkbox>
                                    <InputMd size="small" value={field3_9_2_2} style={{ width: '300px' }} onChange={(e) => { setField3_9_2_2(e.target.value); }} />
                                </Col>
                            </Row>
                            <br /><br /><b>§ 9.2.3</b> Subject to adjustments of the Subcontract Time as provided in the Subcontract Documents, if portions of the Subcontractor’s Work are to be completed prior to substantial completion of the Subcontractor’s Work, then the Subcontractor shall achieve earlier substantial completion of such portions by the following dates.
                            <br /><i>(List all portions of the Subcontractor’s Work required to achieve substantial completion of the Subcontractor’s Portion of the Work.)</i>
                            <br /><br />
                        </Input.Group>
                    </Row>
                    <Row>
                        <Col flex="50%" style={{ paddingLeft: '30px', paddingRight: '50px' }}>
                            <b>Portion of Work</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={portionOfWork} style={{ width: '100%' }} onChange={(e) => { setPortionOfWork(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <b>Substantial Completion</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={substantialCompletion} style={{ width: '100%' }} onChange={(e) => { setSubstantialCompletion(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Group compact>
                            <br /><b>§ 9.2.4</b> If the Subcontractor fails to achieve substantial completion as provided in this Section 9.2, liquidated damages, if any, shall be assessed as set forth in Section 3.4.
                            <br /><br /><b>§ 9.3</b> With respect to the obligations of both the Contractor and the Subcontractor, time is of the essence of this Subcontract.
                            <br /><br /><b>§ 9.4</b> No extension of time will be valid without the Contractor’s written consent after a Claim is made by the Subcontractor in accordance with Section 5.3.

                            <br /><br /><Article>ARTICLE 10</Article><b>SUBCONTRACT SUM</b>
                            <br /><b>§ 10.1</b> The Contractor shall pay the Subcontractor the Subcontract Sum in current funds for the Subcontractor’s performance of the Subcontract. The Subcontract Sum shall be
                            {' '}<InputMd size="small" placeholder="Dollars 0/100" value={field1_10_1} style={{ width: '120px' }} onChange={(e) => { setField1_10_1(e.target.value); }} />
                            {' ('}<InputMd size="small" placeholder="$ 465262.00" value={field2_10_1} style={{ width: '150px' }} onChange={(e) => { setField2_10_1(e.target.value); }} />{') '} , subject to additions and deductions as provided in the Subcontract Documents.
                            <br /><br /><b>§ 10.2 Alternates</b>
                            <br /><b>§ 10.2.1</b> Alternates, if any, excluded in the Subcontract Sum:
                            <br /><br />
                        </Input.Group>
                    </Row>
                    <Row>
                        <Col flex="50%" style={{ paddingLeft: '30px', paddingRight: '50px' }}>
                            <b>Item</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={item10_2_1} style={{ width: '100%' }} onChange={(e) => { setItem10_2_1(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <b>Price</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={price10_2_1} style={{ width: '100%' }} onChange={(e) => { setPrice10_2_1(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Group compact>
                            <br /><b>§ 10.2.2</b> Subject to the conditions noted below, the following alternates may be accepted by the Contractor following execution of this Agreement. Upon acceptance, the Contractor shall issue a Modification to this Subcontract:
                            <br /><i>(Insert below each alternate and the conditions that must be met for the Contractor to accept the alternate.)</i>
                            <br /><br />
                        </Input.Group>
                    </Row>
                    <Row>
                        <Col flex="50%" style={{ paddingLeft: '30px', paddingRight: '50px' }}>
                            <b>Item</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" placeholder="Angle Stop Materials" value={item10_2_2} style={{ width: '100%' }} onChange={(e) => { setItem10_2_2(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="20%" style={{ paddingRight: '20px' }}>
                            <b>Price</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" placeholder="$12.00 per Angle Stop" value={price10_2_2} style={{ width: '100%' }} onChange={(e) => { setPrice10_2_2(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="30%" style={{ paddingRight: '50px' }}>
                            <b>Conditions for Acceptance</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" placeholder="Approved by Consult 2 Build" value={conditionsForAcceptance10_2_2} style={{ width: '100%' }} onChange={(e) => { setConditionsForAcceptance10_2_2(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Group compact>
                            <br /><b>§ 10.3</b> Unit prices, if any:
                            <br /><i>(Identify and state the unit price, and quantity limitations, if any, to which the unit price will be applicable.)</i>
                            <br /><br />
                        </Input.Group>
                    </Row>
                    <Row>
                        <Col flex="50%" style={{ paddingLeft: '30px', paddingRight: '50px' }}>
                            <b>Item</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={item10_3} style={{ width: '100%' }} onChange={(e) => { setItem10_3(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="20%" style={{ paddingRight: '20px' }}>
                            <b>Units and Limitations</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={unitsAndLimitations10_3} style={{ width: '100%' }} onChange={(e) => { setUnitsAndLimitations10_3(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="30%" style={{ paddingRight: '50px' }}>
                            <b>Price Per Unit ($0.00)</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={pricePerUnit10_3} style={{ width: '100%' }} onChange={(e) => { setPricePerUnit10_3(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Group compact>
                            <br /><b>§ 10.4</b> Allowances, if any, included in the Subcontract Sum:
                            <br /><i>(Identify allowance and state exclusions, if any, from the allowance price.)</i>
                            <br /><br />
                        </Input.Group>
                    </Row>
                    <Row>
                        <Col flex="50%" style={{ paddingLeft: '30px', paddingRight: '50px' }}>
                            <b>Item</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={item10_4} style={{ width: '100%' }} onChange={(e) => { setItem10_4(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <b>Price</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" value={price10_4} style={{ width: '100%' }} onChange={(e) => { setPrice10_4(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Group compact>
                            <br /><br /><Article>ARTICLE 11</Article><b>PAYMENTS</b>
                            <br /><b>§ 11.1 Progress Payments</b>
                            <br /><b>§ 11.1.1</b> Based upon Applications for Payment submitted to the Contractor by the Subcontractor, corresponding to Applications for Payment submitted by the Contractor to the Architect, and Certificates for Payment issued by the Architect, the Contractor shall make progress payments on account of the Subcontract Sum to the Subcontractor as provided below and elsewhere in the Subcontract Documents. Unless the Contractor provides the Owner with a payment bond in the full penal sum of the Contract Sum, payments received by the Contractor and Subcontractor for Work properly performed by their contractors and suppliers shall be held by the Contractor and Subcontractor for those contractors or suppliers who performed Work or furnished materials, or both, under contract with the Contractor or Subcontractor for which payment was made to the Contractor by the Owner or to the Subcontractor by the Contractor, as applicable. Nothing contained herein shall require money to be placed in a separate account and not commingled with money of the Contractor or Subcontractor, shall create any fiduciary liability or tort liability on the part of the Contractor or Subcontractor for breach of trust, or shall entitle any person or entity to an award of punitive damages against the Contractor or Subcontractor for breach of the requirements of this provision.
                            <br /><br /><b>§ 11.1.2</b> The period covered by each Application for Payment shall be one calendar month ending on the last day of the month, or as follows:
                            <br /><br /><InputMd size="small" value={field11_1_2} style={{ width: '100%' }} onChange={(e) => { setField11_1_2(e.target.value); }} />
                            <br /><br /><b>§ 11.1.3</b> Provided an Application for Payment is received by the Contractor not later than the{' '}<InputMd size="small" value={field11_1_3} style={{ width: '330px' }} onChange={(e) => { setField11_1_3(e.target.value); }} />, the Contractor shall include the Subcontractor’s Work covered by that application in the next Application for Payment which the Contractor is entitled to submit to the Owner. The Contractor shall pay the Subcontractor each progress payment no later than Friday of each week after the Contractor receives payment from the Owner. If the Owner does not issue a Certificate for Payment or the Contractor does not receive payment for any cause which is not the fault of the Subcontractor, the Contractor shall pay the Subcontractor, on demand, a progress payment computed as provided in Sections 11.1.7, 11.1.8, 11.1.9 and 11.2.
                            <br /><br /><b>§ 11.1.4</b> If the Subcontractor’s Application for Payment is received by the Contractor after the application date fixed above, the Subcontractor’s Work covered by it shall be included by the Contractor in the next Application for Payment submitted to the Architect.
                            <br /><br /><b>§ 11.1.5</b> The Subcontractor shall submit to the Contractor a schedule of values prior to submitting the Subcontractor’s first Application for Payment. Each subsequent Application for Payment shall be based upon the most recent schedule of values submitted by the Subcontractor in accordance with the Subcontract Documents. The schedule of values shall allocate the entire Subcontract Sum among the various portions of the Subcontractor’s Work and be prepared in such form and supported by such data to substantiate its accuracy as the Contractor may require, and unless objected to by the Contractor,  shall be used as a basis for reviewing the Subcontractor’s Applications for Payment.
                            <br /><br /><b>§ 11.1.6</b> Applications for Payment submitted by the Subcontractor shall indicate the percentage of completion of each portion of the Subcontractor’s Work as of the end of the period covered by the Application for Payment.
                            <br /><br /><b>§ 11.1.7</b> Subject to the provisions of the Subcontract Documents, the amount of each progress payment shall be computed as follows:
                            <br /><br /><b>§ 11.1.7.1</b> The amount of each progress payment shall first include:
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>That portion of the Subcontract Sum properly allocable to completed Work:</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>That portion of the Subcontract Sum properly allocable to materials and equipment; and</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.3</Item>
                                        <td>The amount, if any, for changes in the Work that are not in dispute and have been properly authorized by the Contractor, to the same extent provided in the Prime Contract, pending a final determination by the Contractor of the cost of changes in the Subcontractor’s Work, even though the Subcontract Sum has not yet been adjusted. The Subcontractor expressly understands and agrees that it has assumed the risk of loss in the event that the Owner does not pay the Contractor for any such Work performed by the Subcontractor. The Subcontractor further acknowledges that this is a valid and enforceable “Pay When Paid“ clause and it will not allege in any litigation or arbitration which may ensure that this clause does not operate effectively to shift the risk of loss, due to the Owner’s non-payment, from the Contractor to the Subcontractor. This “Pay When Paid“ clause shall apply to progress payments and final payments.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 11.1.7.2</b> The amount of each progress payment shall then be reduced by:
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>The aggregate of previous payments made by the Contractor;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>The amount, if any, for Work that remains uncorrected and for which the Contractor has previously withheld a Certificate for Payment as provided in Article 9 of AIA Document A201-2017 for a cause that is the fault of the Subcontractor;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.3</Item>
                                        <td>For Work performed or defects discovered since the last payment application, any amount for which the Contractor may withhold payment in whole or in part, as provided in Article 9 of AIA Document A201-2017, for a cause that is the fault of the Subcontractor; and</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.4</Item>
                                        <td>Retainage withheld pursuant to Section 11.1.8 of this Agreement.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 11.1.8 Retainage</b>
                            <br /><b>§ 11.1.8.1</b> For each progress payment made prior to substantial completion of the Subcontractor’s Work, the Contractor may withhold the following amounts as retainage from the payment otherwise due:
                            <br /><i>(Insert a percentage or amount to be withheld as retainage from each Application for Payment. The amount of retainage may be limited by governing law.)</i>
                            <br /><br /><InputMd size="small" value={field11_1_8_1} style={{ width: '100%' }} onChange={(e) => { setField11_1_8_1(e.target.value); }} />
                            <br /><br />Notwithstanding anything contained herein to the contrary, any release of retainage or cessation of withholding retainage by Contractor's rights to retainage in connection with any other payments to Subcontractor, or any other right or remedy that Contractor has under the Subcontract Documents, at law, or in equity
                            <br /><br /><b>§ 11.1.8.1.1</b> The following items are not subject to retainage:
                            <br /><i>(Insert any items not subject to the withholding of retainage, such as general conditions, insurance, etc.)</i>
                            <br /><br /><InputMd size="small" value={field11_1_8_1_1} style={{ width: '100%' }} onChange={(e) => { setField11_1_8_1_1(e.target.value); }} />
                            <br /><br /><b>§ 11.1.8.2</b> Reduction or limitation of retainage, if any, shall be as follows:
                            <br /><i>(If the retainage established in Section 11.1.8.1 is to be modified prior to substantial completion of the entire Work, including modifications for substantial completion of portions of the Subcontractor's Work as provided in Section 9.2.3, insert provisions for such modification.)</i>
                            <br /><br /><InputMd size="small" value={field11_1_8_2} style={{ width: '100%' }} onChange={(e) => { setField11_1_8_2(e.target.value); }} />
                            <br /><br /><b>§ 11.1.9</b> Upon the partial or entire disapproval by the Contractor of the Subcontractor’s Application for Payment, the Contractor shall provide notice to the Subcontractor. If the Subcontractor disputes the Contractor’s decision regarding a Subcontractor’s Application for Payment in whole or in part, the Subcontractor may submit a Claim in accordance with Article 6. When the basis for the disapproval has been remedied, the Subcontractor shall be paid the amounts withheld.
                            <br /><br /><b>§ 11.1.10</b> Provided the Contractor has fulfilled its payment obligations under the Subcontract Documents, the Subcontractor shall defend and indemnify the Contractor and Owner from all loss, liability, damage, or expense, including reasonable attorney’s fees and litigation expenses, arising out of any lien claim or other claim for payment by any of the Subcontractor’s subcontractors, suppliers, or vendors of any tier. Upon receipt of notice of such lien claim or other claim for payment, the Contractor shall notify the Subcontractor. If approved by the applicable court, when required, the Subcontractor may substitute a surety bond for the property against which the lien or other claim for payment has been asserted.
                            <br /><br /><b>§ 11.2 Substantial Completion</b>
                            <br />When the Subcontractor’s Work or a designated portion thereof is substantially complete and in accordance with the requirements of the Prime Contract, the Contractor shall, upon application by the Subcontractor, make prompt Application for Payment for such Work. Within 30 days following issuance by the Architect of the Certificate for Payment covering such substantially completed Work, the Contractor shall, to the full extent allowed in the Prime Contract, make payment to the Subcontractor, deducting any portion of the funds for the Subcontractor’s Work withheld in accordance with the certificate to cover costs of items to be completed or corrected by the Subcontractor. Such payment to the Subcontractor shall be the entire unpaid balance of the Subcontract Sum if a full release of retainage is allowed under the Prime Contract for the Subcontractor’s Work prior to the completion of the entire Project. If the Prime Contract does not allow for a full release of retainage, then such payment shall be an amount which, when added to previous payments to the Subcontractor, will reduce the retainage on the Subcontractor’s substantially completed Work to the same percentage of retainage as that on the Contractor’s Work covered by the certificate.
                            <br /><br /><b>§ 11.3 Final Payment</b>
                            <br /><b>§ 11.3.1</b> Final payment, constituting the entire unpaid balance of the Subcontract Sum, shall be made by the Contractor to the Subcontractor when the Subcontractor’s Work is fully performed in accordance with the requirements of the Subcontract Documents, the Architect has issued a Certificate for Payment covering the Subcontractor’s completed Work and the Contractor has received payment from the Owner, final payment to the Subcontractor shall be made upon demand.
                            <br /><i>(Insert provisions for earlier final payment to the Subcontractor, if applicable.)</i>
                            <br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>the Subcontractor has fully performed the Work required by an in accordance with the Subcontract Documents except for the Subcontractor's responsibility to correct Work to satisfy other requirements, if any, which extend beyond final payment;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>the Subcontractor has submitted (i) a final Application for Payment (ii) all required supporting documentation, including, but not limited to, final waivers and releases of liens and claims, conditioned only on receipt of final payment, with all such documentation received from the Subcontractor and all Sub-Subcontractors and supplies on forms approved by the Contractor; and</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.3</Item>
                                        <td>the Architect has issued a Certificate for Payment covering the Subcontractor's completed Work</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.4</Item>
                                        <td>Contractor has received payment from the Owner</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />Prior to final payment, the Subcontractor shall also furnish the Contractor with the following:
                            <br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>(i)</Item>
                                        <td>All maintenance and operating manuals;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>(ii)</Item>
                                        <td>Marked sets of Drawings and Specifications reflecting “as-built“ conditions;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>(iii)</Item>
                                        <td>Reproducible Drawings (to be supplied by the Architect) upon which the Subcontractor shall have transferred all changes in the location of any concealed utilities, mechanical or electrical systems and components;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>(iv)</Item>
                                        <td>any special guarantees or warranties required by the Subcontract Documents;</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 11.3.2</b> Before issuance of the final payment, the Subcontractor, if required, shall submit evidence satisfactory to the Contractor that all payrolls, bills for materials and equipment, and all known indebtedness connected with the Subcontractor's Work have been satisfied. Acceptance of final payment by the Subcontractor shall constitute a waiver of claims by the Subcontractor, except those previously made in writing and identified by the Subcontractor as unsettled at the time of final Application for Payment.
                            <br /><br /><b>§ 11.4 Interest</b>
                            <br />Payments due and unpaid under this Subcontract shall bear interest from the date payment is due at such rate as the parties may agree upon in writing or, in the absence thereof, at the legal rate prevailing from time to time at the place where the Project is located.
                            <br /><i>(Insert rate of interest agreed upon, if any.)</i>
                            <br /><br /><InputMd size="small" value={field11_4} style={{ width: '100%' }} onChange={(e) => { setField11_4(e.target.value); }} />
                            <br /><br /><b>§ 11.5 Joint Checks</b>
                            <br />In the event Contractor has reasonable concerns about the status of payments to Sub-subcontractors, vendors or others, Contractor reserves the right to issue joint checks or to make direct payments to such affected parties.
                            <br /><br /><b>§ 11.6 Payments Made by Affiliates, Subsidiaries and/or Sister Companies</b>
                            <br />Contractor reserves the right to process payments through any of its Affiliates, Subsidiaries or Sister companies. All payments made through any of these companies shall be considered a payment made by the Contractor and all provisions of this Agreement shall apply. List of Affiliates, Subsidiaries or Sister companies below:
                            <br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>JMC Quality Builders Corp</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>LMG Development LLC</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />All necessary documents including but not limited to, Lien Waivers, Subcontractor Affidavits, among others required by the Contractor in order to process any and all payments, shall have the Contractor as the beneficiary of such document.

                            <br /><br /><Article>ARTICLE 12</Article><b>INSURANCE AND BONDS</b>
                            <br /><b>§ 12.1 Subcontractor’s Required Insurance Coverage</b>
                            <br /><b>§ 12.1.1</b> The Subcontractor shall purchase and maintain the following types and limits of insurance, from a company or companies lawfully authorized to issue insurance in the jurisdiction where the Project is located, as will protect the Subcontractor from claims that may arise out of, or result from, the Subcontractor’s operations and completed operations under the
                            <br /><i>(Paragraphs deleted)</i>
                            <br />Subcontract
                            <br /><i>(Paragraphs deleted)</i>
                            <br /><br /><b>§ 12.1.2</b> Commercial General Liability insurance for the Project written on an occurrence form with policy limits of not less than{' '}
                            <InputMd size="small" value={field1_12_1_2} style={{ width: '165px' }} onChange={(e) => { setField1_12_1_2(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field2_12_1_2} style={{ width: '110px' }} onChange={(e) => { setField2_12_1_2(e.target.value); }} />{') '}each occurrence,{' '}
                            <InputMd size="small" value={field3_12_1_2} style={{ width: '165px' }} onChange={(e) => { setField3_12_1_2(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field4_12_1_2} style={{ width: '110px' }} onChange={(e) => { setField4_12_1_2(e.target.value); }} />{' ) '}general aggregate, and{' '}
                            <InputMd size="small" value={field5_12_1_2} style={{ width: '165px' }} onChange={(e) => { setField5_12_1_2(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field6_12_1_2} style={{ width: '110px' }} onChange={(e) => { setField6_12_1_2(e.target.value); }} />{') '}aggregate for products-completed operations hazard, providing coverage for claims including
                            <br />
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>damages because of bodily injury, sickness or disease, including occupational sickness or disease, and death of any person;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>personal and advertising injury;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.3</Item>
                                        <td>damages because of physical damage to or destruction of tangible property, including the loss of use of such property;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.4</Item>
                                        <td>bodily injury or property damage arising out of completed operations; and</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.5</Item>
                                        <td>the Subcontractor’s indemnity obligations under this Agreement.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><b>§ 12.1.3</b> Automobile Liability covering vehicles owned by the Subcontractor and hired and non-owned vehicles used by the Subcontractor, with policy limits of not less than{' '}
                            <InputMd size="small" value={field1_12_1_3} style={{ width: '165px' }} onChange={(e) => { setField1_12_1_3(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field2_12_1_3} style={{ width: '110px' }} onChange={(e) => { setField2_12_1_3(e.target.value); }} />{') '}per accident, for bodily injury, death of any person, and property damage arising out of the ownership, maintenance, and use of those motor vehicles along with any other statutorily required automobile coverage.
                            <br /><br /><b>§ 12.1.4</b> Excess or Umbrella Liability insurance in an amount of not less than{' '}
                            <InputMd size="small" value={field1_12_1_4} style={{ width: '165px' }} onChange={(e) => { setField1_12_1_4(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field2_12_1_4} style={{ width: '110px' }} onChange={(e) => { setField2_12_1_4(e.target.value); }} />{') '}per occurrence.  The Subcontractor may achieve the required limits and coverage for Commercial General Liability and Automobile Liability through a combination of primary and excess or umbrella liability insurance, provided such primary and excess or umbrella insurance policies result in the same or greater coverage as the aggregate coverage required under Sections 12.1.2 and 12.1.3, and in no event shall any excess or umbrella liability insurance provide narrower coverage than the primary policy. The excess policy shall not require the exhaustion of the underlying limits only through the actual payment by the underlying insurers.
                            <br /><br /><b>§ 12.1.5</b> Workers’ Compensation at statutory limits.
                            <br /><br /><b>§ 12.1.6</b> Employers’ Liability with policy limits not less than{' '}
                            <InputMd size="small" value={field1_12_1_6} style={{ width: '165px' }} onChange={(e) => { setField1_12_1_6(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field2_12_1_6} style={{ width: '110px' }} onChange={(e) => { setField2_12_1_6(e.target.value); }} />{') '}each accident,{' '}
                            <InputMd size="small" value={field3_12_1_6} style={{ width: '165px' }} onChange={(e) => { setField3_12_1_6(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field4_12_1_6} style={{ width: '110px' }} onChange={(e) => { setField4_12_1_6(e.target.value); }} />{') '}each employee, and{' '}
                            <InputMd size="small" value={field5_12_1_6} style={{ width: '165px' }} onChange={(e) => { setField5_12_1_6(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field6_12_1_6} style={{ width: '110px' }} onChange={(e) => { setField6_12_1_6(e.target.value); }} />{') '}policy limit.
                            <br /><br /><b>§ 12.1.7</b> Coverages, whether written on an occurrence or claims-made basis, shall be maintained without interruption from the date of commencement of the Subcontractor’s Work until the date of final payment and termination of any coverage required to be maintained after final payment to the Subcontractor, and, with respect to the Subcontractor’s completed operations coverage, until the expiration of the period for correction of Work or for such other period for maintenance of completed operations coverage.
                            <br /><br /><b>§ 12.1.8</b> If professional services are required under Section 4.9, the Subcontractor shall provide the professional liability insurance coverage required under this Section 12.1. Professional Liability insurance covering performance of the such professional services, with policy limits of not less than{''}
                            <InputMd size="small" value={field1_12_1_8} style={{ width: '165px' }} onChange={(e) => { setField1_12_1_8(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field2_12_1_8} style={{ width: '110px' }} onChange={(e) => { setField2_12_1_8(e.target.value); }} />{') '}per claim and{' '}
                            <InputMd size="small" value={field3_12_1_8} style={{ width: '165px' }} onChange={(e) => { setField3_12_1_8(e.target.value); }} />{' ('}
                            <InputMd size="small" value={field4_12_1_8} style={{ width: '110px' }} onChange={(e) => { setField4_12_1_8(e.target.value); }} />{') '}in the aggregate.
                            <br /><br /><b>§ 12.1.9 Certificates of Insurance.</b> The Subcontractor shall provide certificates of insurance acceptable to the Contractor evidencing compliance with the requirements in this Article 12 at the following times: (1) prior to commencement of the Subcontractor’s Work; (2) upon renewal or replacement of each required policy of insurance; and (3) upon the Contractor’s written request. An additional certificate evidencing continuation of liability coverage, including coverage for completed operations, shall be submitted with the final Application for Payment and thereafter upon renewal or replacement of such coverage until the expiration of the time required in this Article 12. The certificates shall show the Contractor and the Owner as additional insureds on the Subcontractor’s Commercial General Liability and any excess or umbrella liability policy.
                            <br /><br /><b>§ 12.1.10 Deductibles and Self-Insured Retentions.</b> The Subcontractor shall disclose to the Contractor any deductible or self-insured retentions applicable to any insurance required to be provided by the Subcontractor.
                            <br /><br /><b>§ 12.1.11 Additional Insured Obligations.</b> To the fullest extent permitted by law, the Subcontractor shall cause its commercial general liability coverage to include: (1) the Contractor, the Owner, the Architect, and the Architect’s consultants as additional insureds for claims caused in whole or in part by the Subcontractor’s negligent acts or omissions during the Subcontractor’s operations; and (2) the Contractor and Owner as additional insureds for claims caused in whole or in part by the Subcontractor’s negligent acts or omissions for which loss occurs during the Subcontractor’s completed operations. The additional insured coverage shall be primary and non-contributory to any of the Contractor’s and Owner’s general liability insurance policies and shall apply to both ongoing and completed operations. To the extent commercially available, the additional insured coverage shall be no less than that provided by Insurance Services Office, Inc. (ISO) CG 20 10 07 04, CG 20 37 07 04, and, with respect to the Architect and the Architect’s consultants, CG 20 32 07 04.
                            <br /><br /><b>§ 12.1.12 Notice of Cancellation or Change in Coverage</b> Within three (3) business days of the date the Subcontractor becomes aware of an impending or actual cancellation or expiration of any insurance required by the Subcontract Documents, the Subcontractor shall provide notice to the Contractor of such impending or actual cancellation or expiration. Upon receipt of notice from the Subcontractor, the Contractor shall, unless the lapse in coverage arises from an act or omission of the Contractor, have the right to suspend the Work in accordance with this Agreement until the lapse in coverage has been cured by the procurement of replacement coverage by the Subcontractor. The furnishing of notice by the Subcontractor shall not relieve the Subcontractor of any contractual obligation to provide any required coverage.
                            <br /><br /><b>§ 12.2 Subcontractor’s Required Performance Bond and Payment Bond</b>
                            <br /><b>§ 12.2.1</b> The Subcontractor shall provide surety bonds, from a company or companies lawfully authorized to issue surety bonds in the jurisdiction where the Project is located, as follows:
                            <br /><i>(Specify type and penal sum of bonds.)</i>
                            <br /><br />
                        </Input.Group>
                    </Row>
                    <Row>
                        <Col flex="50%" style={{ paddingLeft: '30px', paddingRight: '50px' }}>
                            <b>Type</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    Payment Bond<br />
                                    Performance Bond
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <b>Penal Sum ($0.00)</b>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <InputMd size="small" placeholder="N/A" value={field1_12_2_1} style={{ width: '100%' }} onChange={(e) => { setField1_12_2_1(e.target.value); }} /><br />
                                    <InputMd size="small" placeholder="N/A" value={field2_12_2_1} style={{ width: '100%' }} onChange={(e) => { setField1_12_2_1(e.target.value); }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Input.Group compact>
                            <br />Payment and Performance Bonds shall be AIA Document A312TM, Payment Bond and Performance Bond, or contain provisions identical to AIA Document A312TM, current as of the date of this Agreement.
                            <br /><br /><b>§ 12.2.2</b> Upon the request of any person or entity appearing to be a potential beneficiary of bonds covering payment of obligations under this Agreement, the Subcontractor shall promptly furnish a copy of the bonds or shall permit a copy to be made.
                            <br /><br /><b>§ 12.3 Contractor’s Insurance and Bond Obligations</b>
                            <br /><b>§ 12.3.1</b> The Contractor shall furnish to the Subcontractor certificates of insurance evidencing insurance coverage required of the Contractor under the Prime Contract.
                            <br /><br /><b>§ 12.3.2</b> The Contractor shall promptly, upon request of the Subcontractor, furnish a copy or permit a copy to be made of any bond covering payment of obligations arising under the Subcontract.
                            <br /><br /><b>§ 12.4 Property Insurance</b>
                            <br /><b>§ 12.4.1</b> When requested in writing, the Contractor shall provide the Subcontractor with copies of the property and equipment policies in effect for the Project, to the extent copies of the policies are available to the Contractor. The Contractor shall notify the Subcontractor if the required property insurance policies are not in effect.
                            <br /><br /><b>§ 12.4.2</b> If the required property insurance is not in effect for the full value of the Subcontractor’s Work, then the Subcontractor shall purchase insurance for the value of the Subcontractor’s Work, and the Subcontractor shall be reimbursed for the cost of the insurance by an adjustment in the Subcontract Sum.
                            <br /><br /><b>§ 12.4.3</b> Property insurance for the Subcontractor’s materials and equipment required for the Subcontractor’s Work, stored off site or in transit and not covered by the Project property insurance, shall be paid for through the Application for Payment process.
                            <br /><br /><b>§ 12.5 Waivers of Subrogation</b>
                            <br />The Contractor and Subcontractor waive all rights against (1) each other and any of their subcontractors, sub-subcontractors, agents, and employees, each of the other, and (2) the Owner, the Architect, the Architect’s consultants, and (3) Separate Contractors, if any, and any of their subcontractors, sub-subcontractors, agents, and employees for damages caused by fire or other causes of loss to the extent those losses are covered by property insurance provided under the Prime Contract or other property insurance applicable to the Work or to property at or adjacent to the Project site, except such rights as they may have to proceeds of such insurance held by the Owner as a fiduciary. The Subcontractor shall require similar written waivers in favor of the individuals and entities enumerated herein from the Subcontractor’s Sub-subcontractors, agents, and employees. The policies of insurance purchased and maintained by each person or entity agreeing to waive claims pursuant to this Section 12.5 shall not prohibit this waiver of subrogation, which shall be effective as to a person or entity (1) even though that person or entity would otherwise have a duty of indemnification, contractual or otherwise, (2) even though that person or entity did not pay the insurance premium directly or indirectly, or (3) whether or not the person or entity had an insurable interest in the property damaged.

                            <br /><br /><Article>ARTICLE 13</Article><b>TEMPORARY FACILITIES, SERVICES, EQUIPMENT AND WORKING CONDITIONS</b>
                            <br /><b>§ 13.1</b> The Contractor shall furnish and make the Contractor's temporary facilities and services available to the Subcontractor at no cost, except as noted below:
                            <br /><br /><InputMd size="small" value={field1_13_1} style={{ width: '100%' }} onChange={(e) => { setField1_13_1(e.target.value); }} />
                            <br /><br /><b>§ 13.2</b> The Contractor's equipment will be available to the Subcontractor only at the Contractor's discretion and on mutually satisfactory terms, except as noted below:
                            <br /><br /><InputMd size="small" value={field1_13_2} style={{ width: '100%' }} onChange={(e) => { setField1_13_2(e.target.value); }} />
                            <br /><br /><b>§ 13.3</b> Specific working conditions as noted below:
                            <br /><i>(Insert any specific arrangements or requirements concerning working conditions and labor matters applicable to the Subcontractor's Work.)</i>
                            <br /><br /><InputMd size="small" value={field1_13_3} style={{ width: '100%' }} onChange={(e) => { setField1_13_3(e.target.value); }} />

                            <br /><br /><Article>ARTICLE 14</Article><b>MISCELLANEOUS PROVISIONS</b>
                            <br /><b>§ 14.1</b> Where reference is made in this Subcontractor to a provision of another Subcontractor Document, the reference refers to that provision as amended or supplemented by other provisions of the Subcontract Documents.
                            <br /><br /><b>§ 14.2</b> The Contractor’s representative:
                            <br /><i>(Name, address, email address and other information)</i>
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={contractorsRepresentative1.name} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative1({ name: contractorsRepresentative1.name, company: contractorsRepresentative1.company, address: contractorsRepresentative1.address, cityAndState: contractorsRepresentative1.cityAndState, otherInfo: contractorsRepresentative1.otherInfo, mail: contractorsRepresentative1.mail }); }} />
                            <br /><InputMd size="small" placeholder="company" value={contractorsRepresentative1.company} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative1({ name: contractorsRepresentative1.name, company: contractorsRepresentative1.company, address: contractorsRepresentative1.address, cityAndState: contractorsRepresentative1.cityAndState, otherInfo: contractorsRepresentative1.otherInfo, mail: contractorsRepresentative1.mail }); }} />
                            <br /><InputMd size="small" placeholder="address" value={contractorsRepresentative1.address} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative1({ name: contractorsRepresentative1.name, company: contractorsRepresentative1.company, address: contractorsRepresentative1.address, cityAndState: contractorsRepresentative1.cityAndState, otherInfo: contractorsRepresentative1.otherInfo, mail: contractorsRepresentative1.mail }); }} />
                            <br /><InputMd size="small" placeholder="city and state" value={contractorsRepresentative1.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative1({ name: contractorsRepresentative1.name, company: contractorsRepresentative1.company, address: contractorsRepresentative1.address, cityAndState: contractorsRepresentative1.cityAndState, otherInfo: contractorsRepresentative1.otherInfo, mail: contractorsRepresentative1.mail }); }} />
                            <br /><InputMd size="small" placeholder="mail" value={contractorsRepresentative1.mail} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative1({ name: contractorsRepresentative1.name, company: contractorsRepresentative1.company, address: contractorsRepresentative1.address, cityAndState: contractorsRepresentative1.cityAndState, otherInfo: contractorsRepresentative1.otherInfo, mail: contractorsRepresentative1.mail }); }} />
                            <br /><InputMd size="small" placeholder="other info" value={contractorsRepresentative1.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative1({ name: contractorsRepresentative1.name, company: contractorsRepresentative1.company, address: contractorsRepresentative1.address, cityAndState: contractorsRepresentative1.cityAndState, otherInfo: contractorsRepresentative1.otherInfo, mail: contractorsRepresentative1.mail }); }} />
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={contractorsRepresentative2.name} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative2({ name: e.target.value, company: contractorsRepresentative2.company, address: contractorsRepresentative2.address, cityAndState: contractorsRepresentative2.cityAndState, otherInfo: contractorsRepresentative2.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="company" value={contractorsRepresentative2.company} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative2({ name: contractorsRepresentative2.name, company: e.target.value, address: contractorsRepresentative2.address, cityAndState: contractorsRepresentative2.cityAndState, otherInfo: contractorsRepresentative2.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="address" value={contractorsRepresentative2.address} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative2({ name: contractorsRepresentative2.name, company: contractorsRepresentative2.company, address: e.target.value, cityAndState: contractorsRepresentative2.cityAndState, otherInfo: contractorsRepresentative2.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="city and state" value={contractorsRepresentative2.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative2({ name: contractorsRepresentative2.name, company: contractorsRepresentative2.company, address: contractorsRepresentative2.address, cityAndState: e.target.value, otherInfo: contractorsRepresentative2.otherInfo }); }} />
                            <br /><InputMd size="small" placeholder="other info" value={contractorsRepresentative2.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setContractorsRepresentative2({ name: contractorsRepresentative2.name, company: contractorsRepresentative2.company, address: contractorsRepresentative2.address, cityAndState: contractorsRepresentative2.cityAndState, otherInfo: e.target.value }); }} />

                            <br /><br /><b>§ 14.3</b> The Subcontractor’s representative:
                            <br /><i>(Name, address, email address and other information)</i>
                            <br />
                            <br /><InputMd size="small" placeholder="name" value={subcontractorsRepresentative.name} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractorsRepresentative({ name: e.target.value, address: subcontractorsRepresentative.address, cityAndState: subcontractorsRepresentative.cityAndState, otherInfo: subcontractorsRepresentative.otherInfo, mail: subcontractorsRepresentative.mail }); }} />
                            <br /><InputMd size="small" placeholder="address" value={subcontractorsRepresentative.address} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractorsRepresentative({ name: subcontractorsRepresentative.name, address: e.target.value, cityAndState: subcontractorsRepresentative.cityAndState, otherInfo: subcontractorsRepresentative.otherInfo, mail: subcontractorsRepresentative.mail }); }} />
                            <br /><InputMd size="small" placeholder="city and state" value={subcontractorsRepresentative.cityAndState} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractorsRepresentative({ name: subcontractorsRepresentative.name, address: subcontractorsRepresentative.address, cityAndState: e.target.value, otherInfo: subcontractorsRepresentative.otherInfo, mail: subcontractorsRepresentative.mail }); }} />
                            <br /><InputMd size="small" placeholder="mail" value={subcontractorsRepresentative.mail} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractorsRepresentative({ name: subcontractorsRepresentative.name, address: subcontractorsRepresentative.address, cityAndState: subcontractorsRepresentative.cityAndState, otherInfo: e.target.value, mail: subcontractorsRepresentative.mail }); }} />
                            <br /><InputMd size="small" placeholder="other info" value={subcontractorsRepresentative.otherInfo} style={{ width: '100%' }}
                                onChange={(e) => { setSubcontractorsRepresentative({ name: subcontractorsRepresentative.name, address: subcontractorsRepresentative.address, cityAndState: subcontractorsRepresentative.cityAndState, otherInfo: subcontractorsRepresentative.otherInfo, mail: e.target.value }); }} />
                            <br /><br /><b>§ 14.4 Notice</b>
                            <br /><b>§ 14.4.1</b> Except as otherwise provided in Section 14.4.2, where the Subcontract Documents require one party to notify or give notice to the other party, such notice shall be provided in writing to the designated representative of the party to whom the notice is addressed and shall be deemed to have been duly served if delivered in person, by mail, by courier, or by electronic transmission if a method for electronic notice is set forth in Section 14.4.3.
                            <br /><br /><b>§ 14.4.2</b> Notice of Claims shall be provided in writing and shall be deemed to have been duly served only if delivered to the designated representative of the party to whom the notice is addressed by certified or registered mail, or by courier providing proof of delivery.
                            <br /><br /><b>§ 14.4.3</b> Except as otherwise provided in Section 14.4.2, where the Subcontract Documents require one party to notify or give notice to the other party, such notice shall be provided in writing to the designated representative of the party to whom the notice is addressed and shall be deemed to have been duly served if delivered in person, by mail, by courier, or by electronic transmission if a method for electronic notice is set forth in Section 14.4.3.
                            <br /><br /><InputMd size="small" value={field14_4_3} style={{ width: '100%' }} onChange={(e) => { setField14_4_3(e.target.value); }} />
                            <br /><br /><b>§ 14.5</b> Neither the Contractor’s nor the Subcontractor’s representative shall be changed without ten days’ prior notice to the other party.
                            <br /><br /><b>§ 14.6</b> The invalidity of any provision of the Subcontract Documents shall not invalidate the Subcontract or its remaining provisions. If it is determined that any provision of the Subcontract violates any law or is otherwise invalid or unenforceable, then that provision shall be revised to the extent necessary to make that provision legal and enforceable. In such case, the Subcontract shall be construed, to the fullest extent permitted by law, to give effect to the parties' intentions and purposes in executing the Subcontract.
                            <br /><br /><b>§ 14.7</b> The parties shall agree upon protocols governing the transmission and use of Instruments of Service or any other information or documentation in digital form. The parties will use AIA Document E203™–2013, Building Information Modeling and Digital Data Exhibit, to establish the protocols for the development, use, transmission, and exchange of digital data.
                            <br /><br /><b>§ 14.7.1</b> Any use of, or reliance on, all or a portion of a building information model without agreement to protocols governing the use of, and reliance on, the information contained in the model and without having those protocols set forth in AIA Document E203™–2013, Building Information Modeling and Digital Data Exhibit, and the requisite AIA Document G202™–2013, Project Building Information Modeling Protocol Form, shall be at the using or relying party's sole risk and without liability to the other party and its contractors or consultants, the authors of, or contributors to, the building information model, and each of their agents and employess.

                            <br /><br /><Article>ARTICLE 15</Article><b>ENUMERATION OF SUBCONTRACT DOCUMENTS</b>
                            <br /><b>§ 15.1</b> This Agreement is comprised of the following documents:
                            <br />
                            <table>
                                <tbody>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.1</Item>
                                        <td>AIA Document A401™–2017, Standard Form Agreement Between Contractor and Subcontractor;</td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.2</Item>
                                        <td>
                                            Prime Agreement between the Owner and Contractor, including all exhibits.;
                                            <br /><br /><InputMd size="small" placeholder="N/A" value={field1_15_1} style={{ width: '100%' }} onChange={(e) => { setField1_15_1(e.target.value); }} /><br /><br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.3</Item>
                                        <td>
                                            Other Exhibits incorporated into this Agreement:
                                            <br /><i>(Clearly identify any other exhibits incorporated into this Agreement.)</i>
                                            <br /><br /><InputMd size="small" placeholder="N/A" value={field2_15_1} style={{ width: '100%' }} onChange={(e) => { setField2_15_1(e.target.value); }} /><br /><br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <Item style={{ verticalAlign: 'top' }}>.4</Item>
                                        <td>
                                            Other documents:
                                            <br /><i>(List other documents, if any, forming part of the Agreement.)</i>
                                            <br /><br /><InputMd size="small" placeholder="N/A" value={field3_15_1} style={{ width: '100%' }} onChange={(e) => { setField3_15_1(e.target.value); }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>This Agreement entered into as of the day and year first written above.</Row>
                    <br />
                    <Row>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <PCustom><b>CONTRACTOR </b><i>(Signature)</i></PCustom>
                                </Col>
                            </Row>
                            <br />
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <PCustom><i>(Printed name and title)</i></PCustom>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <PCustom><b>SUBCONTRACTOR </b><i>(Signature)</i></PCustom>
                                </Col>
                            </Row>
                            <br />
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <PCustom><i>(Printed name and title)</i></PCustom>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br /><br /><br /><br /><br />
                    <Row style={{ lineHeight: '0.9' }}>
                        <Col flex="100%">
                            <PCustom><DividerCustom /></PCustom>
                            <TextFooter><b>{contractTitle}.</b> Copyright © 1915, 1925, 1937, 1951, 1958, 1961, 1963, 1966, 1967, 1972, 1978, 1987, 1997, 2007 and 2017 by The American Institute of Architects. All rights reserved. The “American Institute of Architects,” “AIA,” the AIA Logo, “A401,” and “AIA Contract Documents” are registered trademarks and may not be used without permission.</TextFooter>
                            <TextFooter> This draft was produced by AIA software at 13:29:16 ET on 09/22/2022 under Order No.2114321925 which expires on 06/08/2023, is not for resale, is licensed for one-time use only, and may only be used in accordance with the AIA Contract Documents® Terms of Service. To report copyright violations, e-mail copyright@aia.org.</TextFooter>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <TextFooter><b>User Notes:</b> AIA_UserNotes</TextFooter>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ textAlign: 'right' }}>
                            <Row wrap={false}>
                                <Col flex="auto">
                                    <TextFooter>{'(1448045638)'}</TextFooter>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Spin >
        </Modal >
    );
}