import { message, Row, Select, Col, Typography, Popconfirm, Spin, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import FormControl from '~/components/Form/FormControl';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import { ModalFooter } from '~/components/Modal';
import errorHandler from '~/Utils/errorHandler';
import JmcContract from './JmcContract';
import JmcAddendum from './JmcAddendum';
import ConsultBuildAddendum from './ConsultBuildAddendum';
import ConsultBuildContract from './ConsultBuildContract';
import { store } from '~/store';
import api from '~/services/api';
import { Table, TableActions } from '~/components/Table';
import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import AIAContract from './AIAContract/AIAContract';

export default function SubcontractorForm({ visible, onClose, record, project }) {
    const { t } = useTranslation();
    const [contractType, setContractType] = useState('');
    const [contractTypes, setContractTypes] = useState([]);

    const handleChange = (value) => {
        setContractType(value);
    };

    useEffect(() => {
        if (visible) {
            if (record !== null) {
                setContractTypes([
                    { value: record.type, label: record.typeDescription }
                ]);
                setContractType(record.type);
            } else {
                const { profile } = store.getState().user;
                let companyName = profile.company !== null ? profile.company.name : '';
                switch (companyName) {
                    case 'JMC Quality Builders Corp.':
                        setContractTypes([
                            { value: 'AIA_SUBCONTRACT_AGREEMENT', label: 'AIA Subcontract Agreement' },
                            { value: 'JMC_SUBCONTRACT_AGREEMENT', label: 'JMC - Subcontract Agreement' },
                            { value: 'JMC_ADDENDUM_CONTRACT_AGREEMENT', label: 'JMC - Addendum to Contract Agreement' },
                        ]);
                        //setContractType('JMC_SUBCONTRACT_AGREEMENT');
                        setContractType('AIA_SUBCONTRACT_AGREEMENT');
                        break;
                    case 'Consult 2 Build Inc.':
                        setContractTypes([
                            { value: 'CONSULT_2_BUILD_SUBCONTRACT_AGREEMENT', label: 'Consult 2 Build - Subcontract Agreement' },
                            { value: 'CONSULT_2_BUILD_ADDENDUM_CONTRACT_AGREEMENT', label: 'Consult 2 Build - Addendum to Contract Agreement' },
                        ]);
                        setContractType('CONSULT_2_BUILD_SUBCONTRACT_AGREEMENT');
                        break;
                    default:
                        setContractTypes([
                            { value: 'JMC_SUBCONTRACT_AGREEMENT', label: 'JMC - Subcontract Agreement' },
                            { value: 'JMC_ADDENDUM_CONTRACT_AGREEMENT', label: 'JMC - Addendum to Contract Agreement' },
                            { value: 'CONSULT_2_BUILD_SUBCONTRACT_AGREEMENT', label: 'Consult 2 Build - Subcontract Agreement' },
                            { value: 'CONSULT_2_BUILD_ADDENDUM_CONTRACT_AGREEMENT', label: 'Consult 2 Build - Addendum to Contract Agreement' },
                        ]);
                        setContractType('JMC_SUBCONTRACT_AGREEMENT');
                        break;
                }
            }
        }
    }, [visible]);

    return (
        <>
            {contractType === 'AIA_SUBCONTRACT_AGREEMENT' ?
               <AIAContract 
               contractTypes={contractTypes}
               contractType={contractType}
               project={project}
               record={record}
               onClose={onClose}
               visible={visible}
               onChangeType={handleChange}
           />  
            : null}
            {contractType === 'JMC_SUBCONTRACT_AGREEMENT' ? 
                <JmcContract 
                    contractTypes={contractTypes}
                    contractType={contractType}
                    project={project}
                    record={record}
                    onClose={onClose}
                    visible={visible}
                    onChangeType={handleChange}
                /> 
            : null}
            {contractType === 'JMC_ADDENDUM_CONTRACT_AGREEMENT' ? 
                <JmcAddendum 
                    contractTypes={contractTypes}
                    contractType={contractType}    
                    project={project} 
                    record={record} 
                    onClose={onClose} 
                    visible={visible}
                    onChangeType={handleChange}
                /> 
            : null}
            {contractType === 'CONSULT_2_BUILD_SUBCONTRACT_AGREEMENT' ? 
                <ConsultBuildContract
                    contractTypes={contractTypes}
                    contractType={contractType}
                    project={project}
                    record={record}
                    onClose={onClose} 
                    visible={visible}
                    onChangeType={handleChange}
                /> 
            : null}
            {contractType === 'CONSULT_2_BUILD_ADDENDUM_CONTRACT_AGREEMENT' ? 
                <ConsultBuildAddendum 
                    contractTypes={contractTypes}
                    contractType={contractType}
                    project={project} 
                    record={record} 
                    onClose={onClose} 
                    visible={visible}
                    onChangeType={handleChange}
                /> 
            : null}
        </>
    );
}