import React, { useState } from 'react';
import DefaultLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { Breadcrumb, Select } from 'antd';
import { BreadcrumbCustom } from '~/styles/global';
import ProfitAndLossReport from './ProfitAndLossReport';
import EstimatedActualReport from './EstimatedActualReport';
import CashFlowReport from './CashFlowReport';

const { Option } = Select;

export default function Reports() {
  const [selectedReport, setSelectedReport] = useState('ProfitAndLoss');

  const handleReportChange = value => {
    setSelectedReport(value);
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item>Reports</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle title="Reports" />
        <div style={{ marginBottom: 16 }}>
          <Select
            value={selectedReport}
            style={{ width: 200 }}
            onChange={handleReportChange}
          >
            <Option value="ProfitAndLoss">Profit and Loss</Option>
            <Option value="EstimatedActual">Estimated vs Actual</Option>
            <Option value="CashFlow">Cash Flow</Option>
            {/* Future reports can be added here */}
          </Select>
        </div>
        {selectedReport === 'ProfitAndLoss' && <ProfitAndLossReport />}
        {selectedReport === 'EstimatedActual' && <EstimatedActualReport />}
        {selectedReport === 'CashFlow' && <CashFlowReport />}
      </Box>
    </DefaultLayout>
  );
}