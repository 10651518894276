import styled from 'styled-components';
import { Divider, Typography, Input } from "antd";

const { Text } = Typography;

export const Article = styled(Text)`
    width: 90px;
    font-Weight: 600;
`;

export const DividerCustom = styled(Divider)`
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.85);
    margin: 20px 0px 0px 0px;
`;

export const ImageCustom = styled.img`
    display: block;
    margin-right: 10px;
    height: 30px;
`;

export const InputMd = styled(Input)`
    background-color: #c0c0c0;
`;

export const InputTitle = styled(Input)`
    background-color: #c0c0c0;
    font-size: 22px;
    font-Weight: 600;
    font-family: Courier New, sans-serif;
`;

export const Item = styled.td`
    width: 90px;
    font-Weight: 600;
    text-align: right;
    padding-right: 25px;
    height: '100%';
`;

export const PCustom = styled.p`
    margin-bottom: 0px;
`;

export const Summary = styled(Text)`
    font-Weight: 600;
`;

export const SummaryItem = styled(Text)`
    width: 35px;
    font-Weight: 600;
`;

export const TextFooter = styled(Text)`
    font-size: 8px;
    font-family: Courier New, sans-serif;
`;

export const TextSm = styled(Text)`
    font-size: 10px;
    font-family: Courier New, sans-serif;
`;

export const Title = styled(Text)`
    font-size: 16px;
    font-Weight: 600;
    font-Style: italic;
    font-family: Courier New, sans-serif;
`;