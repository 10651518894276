import { message, Row, Col, Popconfirm, Badge, Button as ButtonAntd } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { Table, TableActions } from '~/components/Table';
import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import SubcontractorForm from './form';
import SendContractModal from './modal';
import { approveContract } from '~/services/hooks/contracts';
import ModalSignatureCanvas from '~/components/ModalSignatureCanvas/ModalSignatureCanvas';

export default function Subcontractor({ project }) {
    const { t } = useTranslation();
    const roleList = useSelector(state => (state.user.profile ? state.user.profile.roles : null));
    const token = useSelector(state => state.auth.token);
    const [loading, setLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [showSendContractModal, setSendContractModal] = useState(false);
    const [showSignatureModal, setSignatureModal] = useState(false);
    const [meta, setMeta] = useState({});
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState([]);

    const fetchRecords = async (filters) => {
        setLoading(true);
        try {
            filters.push({ columnJoin: 'project', field: 'id', value: project.id, restriction: 'EQUAL' });

            const pagePaginationDTO = { page: 0, sortBy: 'date', filters: filters };
            const { data } = await api.post('/contracts/pagination', pagePaginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleEdit = record => {
        setSelectedRecord(record);
        setShowForm(true);
    };

    const handleDelete = async id => {
        setLoading(true);

        try {
            await api.delete(`/contracts/delete/${id}`);
            fetchRecords(filters);
            message.success(t('messages:contracts.successDeleted'));
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleChangePage = async page => {
        setLoading(true);
        try {
            const paginationDTO = { page: page - 1, sortBy: 'date', filters: filters };
            const { data } = await api.post('/contracts/pagination', paginationDTO);

            const meta = {
                first: data.first,
                last: data.last,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                pageNumber: data.pageable.pageNumber + 1,
                pageSize: data.pageable.pageSize,
            };

            setMeta(meta);
            setTableData(data.content);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleDownload = async (record) => {
        setLoading(true);
        try {
            axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/contracts/generateContract/${record.id}`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob',
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${record.typeDescription}.pdf`);
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleSignatureChange = async ({ name, jobRole, signatureResult, rubricResult, password }) => {
        try {
          const formData = new FormData();
          formData.append('contractId', selectedRecord.id);
          formData.append('name', name);
          formData.append('jobRole', jobRole);
          formData.append('signature', signatureResult);
          formData.append('rubric', rubricResult);
          formData.append('password', password);

          await approveContract(formData);
          setSignatureModal(false);
          setFilters([]);
        } catch (error) {
          errorHandler(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchRecords([]);
    }, [showForm, showSendContractModal, showSignatureModal]);

    const style = {
        btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
        btnClear: { marginLeft: 'auto', marginTop: 25 },
        btnNew: {
            col: { marginLeft: 'auto', paddingBottom: '20px' },
            btn: { marginLeft: 'auto' }
        },
        sent: { backgroundColor: '#52c41a' },
        notSent: { backgroundColor: '#d9d9d9', color: 'gray' },
        actions: { paddingLeft: '5px', paddingRight: '5px' }
    }

    const tableColumns = [
        {
            title: t('screens:contracts.data.number'),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: t('screens:contracts.data.type'),
            dataIndex: 'typeDescription',
            key: 'typeDescription',
        },
        {
            title: t('screens:contracts.data.subcontractor'),
            dataIndex: 'subcontractor',
            key: 'subcontractor',
        },
        {
            title: t('screens:contracts.data.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record) => (
                    record.sentDate === null ? 
                        <Badge count={t('screens:contracts.data.notSent')} style={style.notSent} title={''} />
                    : record.approvedByJmc === true ?
                        <Badge count={t('screens:contracts.data.approved')} style={style.sent} title={'APPROVED'} />
                    : record.approvedBySub === true ?
                        roleList && roleList[0].name.includes('ROLE_ADMIN') || 
                        roleList[0].name.includes('ROLE_SUPER_ADMIN') ? 
                            (<ButtonAntd
                                type="primary"
                                size='small'
                                style={style.btnApprove}
                                title={t('Approve the contract')}
                                onClick={() => {
                                setSelectedRecord(record);
                                setSignatureModal(true);
                                }}>
                                {t('screens:contracts.data.approve')}
                            </ButtonAntd>)
                        : 
                        <Badge count={t('screens:contracts.data.waitingForApproval')} style={style.notSent} title={'WAITING FOR APPROVAL'} />
                    :
                        <Badge count={t('screens:contracts.data.sent')} style={style.sent} title={record.sentDate} />
            ),
        },
        {
            title: t('screens:contracts.data.actions'),
            key: 'actions',
            align: 'center',
            width: '10%',
            className: 'table-action',
            render: (text, record) => (
                <TableActions>
                    <Button
                        style={style.actions}
                        onClick={() => {
                            setSelectedRecord(record);
                            setSendContractModal(true);
                        }}
                        title={t('messages:sendContractToSubcontractor')}>
                        <SendOutlined />
                    </Button>
                    <Button style={style.actions} onClick={() => handleDownload(record)} title={t('messages:download')}><DownloadOutlined /></Button>
                    <Button style={style.actions} onClick={() => handleEdit(record)} title={t('messages:edit')}><EditOutlined /></Button>

                    <Popconfirm
                        icon={false}
                        title={t('messages:confirmDelete')}
                        onConfirm={() => handleDelete(record.id)}
                        okText={t('messages:yes')}
                        cancelText={t('messages:no')}
                    >
                        <Button style={style.actions} title={t('messages:delete')}><DeleteOutlined /></Button>
                    </Popconfirm>
                </TableActions>
            ),
        },
    ];

    return (
        <>
            <Box>
                <Row>
                    <Col span={24} style={style.btnNew.col}>
                        <Button
                            loading={loading}
                            color="primary"
                            onClick={() => {
                                setSelectedRecord(null);
                                setShowForm(true);
                            }}
                            style={style.btnNew.btn}
                        >
                            <PlusOutlined />
                            {t('screens:contracts.btnNew')}
                        </Button>
                    </Col>
                </Row>
                <Table
                    size="small"
                    bordered
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={tableData}
                />

                <Pagination
                    onChange={value => {
                        handleChangePage(value);
                    }}
                    meta={meta}
                />
                <SubcontractorForm project={project} record={selectedRecord} visible={showForm} onClose={() => setShowForm(false)} />
                <SendContractModal record={selectedRecord} visible={showSendContractModal} onClose={() => setSendContractModal(false)} />
                <ModalSignatureCanvas
                    record={selectedRecord}
                    visible={showSignatureModal}
                    loading={loading}
                    jcmSignature={true}
                    canvasProps={{ width: 450, height: 80, className: 'sigCanvas' }}
                    onSave={handleSignatureChange}
                    onLoad={() => { setLoading(true)}}
                    onClose={() => {
                    setSignatureModal(false);
                    setFilters([]);
                    }}
                />
            </Box>
        </>
    );
}