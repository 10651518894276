import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import mockData from './estimatedActualMock';
import './style.css';

export default function EstimatedActualReport() {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    // Parse the mock JSON data and set the data source for the table
    const data = parseProfitAndLossData(mockData);
    setDataSource(data);
  }, []);

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, record) => {
        const indent = record.level * 20;
        return <span style={{ paddingLeft: indent }}>{text}</span>;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: text => {
        if (text === '') return '';
        const formattedNumber = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(text));
        return formattedNumber;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      bordered
      rowClassName={record => (record.isSummary ? 'summary-row' : '')}
    />
  );
}

function parseProfitAndLossData(data) {
  const rows = data.Rows.Row;
  const parsedRows = [];

  function traverseRows(rowsArray, level = 0) {
    rowsArray.forEach(row => {
      if (row.Header) {
        const accountName = row.Header.ColData[0].value;
        parsedRows.push({
          key: `${accountName}-${level}-${Math.random()}`,
          account: accountName,
          total: '',
          level,
          isSummary: false,
        });
      }

      if (row.ColData) {
        const accountName = row.ColData[0].value;
        const totalValue = row.ColData[1].value;
        parsedRows.push({
          key: `${accountName}-${level}-${Math.random()}`,
          account: accountName,
          total: totalValue,
          level,
          isSummary: row.type === 'Data',
        });
      }

      if (row.Rows) {
        traverseRows(row.Rows.Row, level + 1);
      }

      if (row.Summary) {
        const summaryName = row.Summary.ColData[0].value;
        const totalValue = row.Summary.ColData[1].value;
        parsedRows.push({
          key: `${summaryName}-${level}-${Math.random()}`,
          account: summaryName,
          total: totalValue,
          level,
          isSummary: true,
        });
      }
    });
  }

  traverseRows(rows);

  return parsedRows;
}
