import { Card, Col, DatePicker, Input, Row, Select, Spin, Modal, message, Typography } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import api from '~/services/api';
import errorHandler from 'Utils/errorHandler';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { Table } from '~/components/Table';
import { formatPrice } from '~/Utils';
import { Container, ImageCustom, TextMd, InputCustom, PCustom, DividerCustom, InputCustomMd, Scope, TextAreaCustom } from './style';

export default function ConsultBuildAddendum({ contractTypes, contractType, project, record, onClose, onChangeType, visible }) {
    const logo = "/assets/images/logo-consult2build.png";
    const currentDate = moment().format('DD') + 'th day of ' + moment().format('MMMM') + ' in the year ' + moment().format('YYYY');
    const { t } = useTranslation();
    const { Option } = Select;
    const { Text } = Typography;
    const [loading, setLoading] = useState(false);
    const [subVendor, setSubVendor] = useState(null);
    const [items, setItems] = useState([]);
    const [subContractors, setSubContractors] = useState([]);
    const [subContractorOptions, setSubContractorOptions] = useState([]);
    const [projectNumber, setProjectNumber] = useState('');
    const [dateDescription, setDateDescription] = useState(currentDate);
    const [subcontractor, setSubcontractor] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [projectAddress, setProjectAddress] = useState('');
    const [cityStateZipCode, setCityStateZipCode] = useState('');
    const [subcontractValue, setSubcontractValue] = useState('0.00');
    const [date, setDate] = useState(moment());

    const [changeOrder, setChangeOrder] = useState(null);
    const [changeOrderId, setChangeOrderId] = useState(null);
    const [changeOrders, setChangeOrders] = useState(null);

    const fetchRecords = () => {
        if (record !== null) {
            setSubVendor(record.subVendor);
            setProjectNumber(record.projectNumber);
            setDateDescription(record.dateDescription);
            setSubcontractor(record.subcontractor);
            setProjectName(record.projectName);
            setProjectAddress(record.projectAddress);
            setCityStateZipCode(record.cityStateZipCode);
            setSubcontractValue(record.subcontractValue);
            setDate(moment(record.date));
            setChangeOrder(record.changeOrder);
            setChangeOrderId(record.changeOrder !== null ? record.changeOrder.id : null);
            setChangeOrders([]);
            setItems(record.items);

            var bigDecimal = require('js-big-decimal');
            var total = new bigDecimal('0.00');
            record.items.forEach((item) => {
                total = total.add(new bigDecimal(item.total));
            });
                
            setItems(record.items);
            setSubcontractValue(total.round(2).getValue());
        } else {
            setSubVendor(null);
            setItems([]);
            setSubContractors([]);
            setSubContractorOptions([]);
            setProjectNumber('');
            setDateDescription(currentDate);
            setSubcontractor(null);
            setProjectName('');
            setProjectAddress('');
            setCityStateZipCode('');
            setSubcontractValue('0.00');
            setDate(moment());
            setChangeOrder(null);
            setChangeOrderId(null);
            setChangeOrders([]);

            if (project) {
                setProjectNumber(project.id);
                setProjectName(project.description);

                if (project.property && project.property.address) {
                    const address = project.property.address;
                    setProjectAddress(address.addressLineOne);
                    setCityStateZipCode(address.city + ", " + address.state + address.zipCode);
                }
            }
            fetchChangeOrders();
        }
    };

    const fetchItems = async (changeOrder) => {
        try {
            if (changeOrder) {
                const { data } = await api.get('/contracts/generateContractItemsByChangeOrder', {
                    params: { changeOrderId: changeOrder.id }
                });

                var bigDecimal = require('js-big-decimal');
                setItems(data.items);
                var total = new bigDecimal(data.total);
                setSubcontractValue(total.round(2).getValue());
            } else {
                setItems([]);
                setSubcontractValue('0.00');
            }
        } catch (error) {
            errorHandler(error);
        }
    }

    const fetchSubContractors = async (subcontractor) => {
        setLoading(true);
        try {
            let subcontractorId = subcontractor.id;
            const filterList = [
                { field: 'id', value: subcontractorId, restriction: 'EQUAL' },
                { field: 'type', value: 'SUBCONTRACTOR', restriction: 'EQUAL' }
            ];
            const { data } = await api.post('/subVendors/findAllPost', filterList);
            let options = [];
            data.forEach((item) => {
                options.push({ key: item.id, value: item.companyName, label: item.companyName });
            });

            setSubContractors(data);
            setSubContractorOptions(options);
            setSubcontractor(subcontractor.companyName);

            data.some((item) => {
                if (item.companyName === subcontractor.companyName) {
                    setSubVendor(item);
                    return true;
                }
            });
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const fetchChangeOrders = async () => {
        setLoading(true);
        try {
            const filterList = [{ columnJoin: 'project', field: 'id', value: project.id, restriction: 'EQUAL' }];
            const { data } = await api.post('/changeOrders/findAll', filterList);
            let options = [];
            data.forEach((item) => {
                options.push({ key: item.id, value: item.id, label: item.id.toString() });
            });

            setChangeOrders(data);
        } catch (error) {
            errorHandler(error);
        }
        setLoading(false);
    };

    const handleChange = (value) => {
        onChangeType(value);
    };

    const handleChangeSubcontractor = (value) => {
        setSubcontractor(value);

        subContractors.some((item) => {
            if (item.companyName === value) {
                setSubVendor(item);
                return true;
            }
        });
    };

    const handleChangeOrder = (value) => {
        setChangeOrderId(value);

        changeOrders.some((item) => {
            if (item.id.toString() === value) {
                setChangeOrder(item);
                fetchSubContractors(item.subcontractor);
                fetchItems(item);
                return true;
            }
        });
    };

    const contractSchema = Yup.object({
        changeOrder: Yup.string().required("Change order is required!"),
    });

    const handleSave = async () => {
        setLoading(true);

        try {
            let newContract = {
                id: record !== null ? record.id : null,
                project: record !== null ? record.project : project,
                subVendor: record !== null ? record.subVendor : subVendor,
                changeOrder: record !== null ? record.changeOrder : changeOrder,
                type: contractType,
                projectNumber: projectNumber,
                dateDescription: dateDescription,
                subcontractor: subcontractor,
                projectName: projectName,
                projectAddress: projectAddress,
                cityStateZipCode: cityStateZipCode,
                subcontractValue: subcontractValue,
                date: moment(date).format('YYYY-MM-DD HH:mm:s'),
                sentDate: record !== null ? record.sentDate : null,
                arquive: record !== null ? record.arquive : null,
                contractSignature: record !== null ? record.contractSignature : null,
                items: items,
            };

            const contractValidate = {
                changeOrder: newContract.changeOrder !== null ? newContract.changeOrder.id.toString() : '',
            };

            contractSchema.validate(contractValidate);

            if (record !== null) {
                await api.put('/contracts/update', newContract);
                message.success(t('messages:contracts.successEdit'));
            } else {
                await api.post('/contracts/create', newContract);
                message.success(t('messages:contracts.success'));
            }
            onClose();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                message.error(error.message);
            } else {
                errorHandler(error);
            }
        }
        setLoading(false);
    };

    const tableColumns = [
        {
            title: t('screens:changeOrders.item.data.code'),
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: t('screens:changeOrders.item.data.title'),
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: t('screens:changeOrders.item.data.quantity'),
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: t('screens:changeOrders.item.data.unit'),
            dataIndex: 'unit',
            key: 'unit'
        },
        {
            //title: t('screens:changeOrders.item.data.unit_cost'),
            title: t('screens:changeOrders.item.data.unitCost'),
            dataIndex: 'value',
            key: 'value',
            render: value => formatPrice(value)
        },
        {
            title: t('screens:changeOrders.item.data.total'),
            dataIndex: 'total',
            key: 'total',
            render: total => formatPrice(total)
        },
    ];

    function getSubtotal(item) {
        var bigDecimal = require('js-big-decimal');
        var subtotal = new bigDecimal('0.00');
        var quantity = new bigDecimal(item.quantity);
        var unitCost = new bigDecimal(item.unitCost);
        subtotal = subtotal.add(quantity.multiply(unitCost));
        return subtotal.round(2).getValue();
    };

    useEffect(() => {
        fetchRecords();
    }, [record]);

    return (
        <Modal
            width="95%"
            title={record !== null ? t('screens:contracts.btnEdit') : t('screens:contracts.btnNew')}
            onCancel={onClose}
            open={visible}
            loading={loading}
            footer={
                <ModalFooter onOk={handleSave} loading={loading} onCancel={onClose} cancelColor="default" />
            }
        >
            <Spin spinning={loading}>
                <Row>
                    <FormControl
                        cols={{ xs: 24, sm: 18, md: 13, lg: 11, xl: 9 }}
                        field="contractType"
                        label={t('screens:subcontractor.data.contractType')}
                    >
                        <Select
                            style={{ width: 370 }}
                            name="contractType"
                            placeholder="Select"
                            disabled={record !== null}
                            defaultValue={contractType}
                            onChange={handleChange}
                        >
                            {contractTypes?.map(item => {
                                return (
                                    <Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl
                        cols={{ xs: 24, sm: 6, md: 11, lg: 13, xl: 15 }}
                        field="changeOrder"
                        label={t('screens:subcontractor.data.changeOrder')}
                    >
                        <Select
                            style={{ width: 120 }}
                            name="changeOrder"
                            placeholder="Select"
                            disabled={record !== null}
                            defaultValue={changeOrderId}
                            onChange={handleChangeOrder}
                        >
                            {changeOrders?.map(item => {
                                return (
                                    <Option key={item.id} value={item.id.toString()}>{item.id}</Option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Row>
                <Card>
                    <Row>
                        <ImageCustom src={logo} />
                    </Row>
                    <Container><TextMd strong>CONSULT 2 BUILD, INC. ADDENDUM TO</TextMd></Container>
                    <Container><TextMd strong>CONTRACT AGREEMENT</TextMd></Container>
                    <br />
                    <Row>
                        <Input.Group compact>
                            This addendum to the project number
                            <Text strong style={{ paddingLeft: '5px' }}>{projectNumber}</Text>
                            , made as of the
                            <InputCustom size="small" value={dateDescription} style={{ width: '260px' }}
                                onChange={(e) => { setDateDescription(e.target.value); }}
                            />
                            , by and between CONSULT 2 BUILD, INC., and
                            {record === null ?
                                <Select size="small" showSearch value={subcontractor} placeholder="Select subcontractor"
                                    options={subContractorOptions}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={handleChangeSubcontractor}

                                />
                                :
                                <InputCustom size="small" disabled={true} value={subcontractor} style={{ width: '200px' }}
                                    onChange={(e) => { setSubcontractor(e.target.value); }}
                                />
                            }
                            for the following project:
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>
                        <InputCustom size="small" value={projectName}
                            style={{ width: '250px', fontWeight: '600' }}
                            onChange={(e) => { setProjectName(e.target.value); }} />
                    </Row>
                    <Row>
                        <TextAreaCustom autoSize="true" size="small" value={projectAddress}
                            style={{ width: '100%', fontWeight: '600' }}
                            placeholder="Insert the project address"
                            onChange={(e) => { setProjectAddress(e.target.value); }} />
                    </Row>
                    <Row>
                        <InputCustom size="small" value={cityStateZipCode}
                            style={{ width: '250px', fontWeight: '600' }}
                            placeholder="Insert the city, state and zip code"
                            onChange={(e) => { setCityStateZipCode(e.target.value); }} />
                    </Row>
                    <br />
                    <Row>The following scope of work and amounts are to be added to the contract as per schedule of values below:</Row>
                    <br />
                    <Row>
                        <Table
                            size="small"
                            style={{ width: '100%' }}
                            bordered
                            rowKey="id"
                            pagination={false}
                            loading={loading}
                            columns={tableColumns}
                            dataSource={items}
                        />
                    </Row>
                    <br />
                    <Row>
                        <Input.Group compact>
                            The contractor shall pay the subcontractor in current funds for the performance of the above the sum of
                            <Text strong style={{ paddingLeft: '5px' }}>{formatPrice(subcontractValue)}</Text>.
                        </Input.Group>
                    </Row>
                    <br />
                    <Row>All payments made and retainage held shall be adjusted accordingly.</Row>
                    <br />
                    <Row>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            <PCustom style={{ fontWeight: '600', textDecoration: 'underline' }}>CONSULT 2 BUILD INC.</PCustom>
                            <br />
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="none" style={{ width: '50px' }}>
                                    <PCustom>By:</PCustom>
                                    <PCustom>Phone:</PCustom>
                                    <PCustom>Email:</PCustom>
                                    <PCustom>As:</PCustom>
                                    <PCustom style={{ paddingTop: '5px' }}>Date:</PCustom>
                                </Col>
                                <Col flex="auto">
                                    <PCustom>Marco Garavelo</PCustom>
                                    <PCustom>407 704 7834</PCustom>
                                    <PCustom>marco@jmcquality.com</PCustom>
                                    <PCustom>Principal – Consult 2 Build Inc</PCustom>
                                    <PCustom>
                                        <DatePicker
                                            format={"YYYY/MM/DD"}
                                            placeholder={t('messages:select')}
                                            value={date}
                                            onChange={(date) => setDate(date)}
                                        />
                                    </PCustom>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="50%" style={{ paddingRight: '50px' }}>
                            {subcontractor !== '' ? <PCustom style={{ fontWeight: '600', textDecoration: 'underline' }}>{subcontractor}</PCustom> : <br />}
                            <br />
                            <PCustom><DividerCustom /></PCustom>
                            <Row wrap={false}>
                                <Col flex="none" style={{ width: '50px' }}>
                                    <PCustom>By:</PCustom>
                                    <PCustom>Phone:</PCustom>
                                    <PCustom>Email:</PCustom>
                                    <PCustom>As:</PCustom>
                                    <PCustom>Date:</PCustom>
                                </Col>
                                <Col flex="auto">
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                    <PCustom><DividerCustom /></PCustom>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </Modal>
    );
}